import React, { useState } from "react";
import { Label, Input, FormFeedback } from "reactstrap";

export interface TextInputProps {
  name: string;
  label: string;
  validation: any;
}

export const SiemprefyTextInput = (props: TextInputProps) => {
  const [value, setValue] = useState("");

  return (
    <>
      <Label className="form-label">{props.label}</Label>
      <Input
        name={props.name}
        className="form-control"
        placeholder={`Enter ${props.label}`}
        type="text"
        onChange={props.validation.handleChange}
        onBlur={props.validation.handleBlur}
        value={props.validation.values[props.name] || ""}
        invalid={
          props.validation.touched[props.name] &&
          props.validation.errors[props.name]
            ? true
            : false
        }
      />
      {props.validation.touched[props.name] &&
      props.validation.errors[props.name] ? (
        <FormFeedback type="invalid">
          {props.validation.errors[props.name]}
        </FormFeedback>
      ) : null}
    </>
  );
};
