import Error404 from "src/pages/Utility/Error404";
import Error500 from "src/pages/Utility/Error500";
import LockScreen from "src/pages/AuthenticationInner/LockScreen";
import Admin from "src/pages/Siemprefy/Admin/Admin";
import AddEditQR from "src/pages/Siemprefy/AddEditQR";
import PageTimeline from "src/pages/Utility/PageTimeline";
import QREvents from "src/pages/Siemprefy/Events/QREvents";
import { Payments } from "src/pages/Siemprefy/Payments/Payments";
import ContactOwner from "src/pages/AuthenticationInner/ContactOwner";
import PageFaqs from "src/pages/Utility/PageFAQs";
import { LoginScreen } from "./middleware/LoginScreen";

interface RouteProps {
  path: string;
  component: any;
  exact?: boolean;
}

const userRoutes: Array<RouteProps> = [
  { path: "/admin", component: Admin },
  { path: "/qr", component: AddEditQR },
  { path: "/events", component: QREvents },
  { path: "/payment", component: Payments },
];

const authRoutes: Array<RouteProps> = [
  //AuthenticationInner pages
  { path: "/log-out", component: LoginScreen },
  { path: "/page-lock-screen", component: LockScreen },
  { path: "/contact-owner", component: ContactOwner },

  //utility page
  { path: "/pages-404", component: Error404 },
  { path: "/pages-500", component: Error500 },
  { path: "/", exact: true, component: PageTimeline },
];

export { userRoutes, authRoutes };
