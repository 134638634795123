import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md={6}>{new Date().getFullYear()} © Siemprefy.</Col>
            <Col md={6}>
              <div className="text-sm-end d-none d-sm-block">
                Built by
                <a
                  className="ms-1 text-decoration-underline"
                  href="https://sophrosyn3.com/"
                >
                  Sophrosyn3 LLC
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
