import { assertIsDefined } from "src/common/assert";
import {
  DataSchema,
  ItemWithKnownData,
  QRData,
  QREvent,
} from "src/common/Siemprefy";

/*
 * Fetch the data schema id so that we don't have to know before creating
 * @param tableName The table name
 * @param item The item to look in
 * @returns The dataschemaId GUID or undefined if none found
 */
export const getDataSchemaId = <T>(
  tableName: string,
  item: ItemWithKnownData<T>
): string | undefined => {
  const qrEventRow: DataSchema<T> | undefined = item.data.find((row) => {
    return Object.keys(row).includes(tableName);
  });

  return qrEventRow?.dataSchemaId;
};

export const getDataSchema = <T>(
  tableName: string,
  item: ItemWithKnownData<T>
): T | undefined => {
  assertIsDefined(item, "item should be exist!");

  const dataSchemaId = getDataSchemaId(tableName, item);
  const qrEventRow: DataSchema<T> | undefined = item.data.find((row) => {
    return row.dataSchemaId === dataSchemaId;
  });

  return qrEventRow ? qrEventRow[tableName] : undefined;
};

export const setDataSchema = <T>(
  tableName: string,
  item: ItemWithKnownData<T>,
  newRow: DataSchema<T>
): ItemWithKnownData<T> => {
  assertIsDefined(item, "item should be exist!");

  const dataSchemaId = getDataSchemaId(tableName, item);
  if (dataSchemaId) {
    const newData = item.data;
    const index = newData.findIndex((row) => {
      if (row.dataSchemaId === dataSchemaId) {
        if (newRow[`${tableName}Id`]) {
          return row[tableName][`${tableName}Id`] === newRow[`${tableName}Id`];
        } else {
          return row.dataSchemaId === dataSchemaId;
        }
      }
    });

    newData[index][tableName] = newRow;
    item.data = newData;
  } else {
    item.data.push({
      dataSchemaId: undefined,
      qrEvent: newRow,
    });
  }

  return item;
};

export interface TimelineDataSchemas {
  qrData: QRData;
  qrEvents: Array<QREvent>;
}

export const pluckTimelineDataSchemas = (
  response: ItemWithKnownData<QREvent | QRData>
): TimelineDataSchemas => {
  return {
    qrData: pluckQRData(response),
    qrEvents: pluckQREvents(response),
  };
};

export const pluckQRData = (response: ItemWithKnownData<QREvent | QRData>) => {
  const qrDataRow: DataSchema<QRData> | undefined = response.data.find(
    (row) => {
      return Object.keys(row).includes("qrData");
    }
  );
  assertIsDefined(qrDataRow, "data row must exist!");
  const qrDataDataSchemaId = qrDataRow.dataSchemaId;
  const qrData: QRData = response.data
    .filter((dataRow) => {
      return dataRow.dataSchemaId === qrDataDataSchemaId;
    })
    .map((dataRow: DataSchema<QRData>) => {
      return {
        ...dataRow["qrData"],
        userDob: new Date(dataRow["qrData"].userDob),
        userDod: new Date(dataRow["qrData"].userDod),
      };
    })[0];

    return qrData;
};

export const pluckQREvents = (response: ItemWithKnownData<QREvent | QRData>) => {
  const qrEventRow: DataSchema<QREvent> | undefined = response.data.find(
    (row) => {
      return Object.keys(row).includes("qrEvent");
    }
  );
  const qrEventDataSchemaId = qrEventRow?.dataSchemaId;

  const qrEvents: Array<QREvent> = response.data
    .filter((dataRow: DataSchema<QREvent>) => {
      return (
        dataRow.dataSchemaId === qrEventDataSchemaId &&
        !dataRow["qrEvent"].deleted
      );
    })
    .map((dataRow: DataSchema<QREvent>) => {
      return {
        ...dataRow["qrEvent"],
        date: new Date(dataRow["qrEvent"].date),
      };
    })
    .sort((qrEventA: QREvent, qrEventB: QREvent) => {
      return (qrEventA.date as any) - (qrEventB.date as any);
    });

    return qrEvents;
};

export const removeDataSchemaRow = (
  item: ItemWithKnownData<QREvent | QRData>,
  tableName: string,
  rowId: number) => {
  const dataSchemaId = getDataSchemaId(tableName, item);
  const indexToRemove = item.data.findIndex((row) => {
    if (
      row.dataSchemaId === dataSchemaId &&
      row[tableName].qrEventId === rowId
    ) {
      return row;
    }
  });
  const newDataRow = [
    ...item.data.slice(0, indexToRemove),
    ...item.data.slice(indexToRemove + 1, item.data.length),
  ];

  return newDataRow;
};
