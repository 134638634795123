import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

//import images
import logo from "../../assets/images/logo-sm.svg";

const LoadingPage = () => {
  return (
    <React.Fragment>
      <div className="bg-soft-light min-vh-100 py-5">
        <div className="py-4">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center">
                  <div className="mb-5">
                    <Link to="/dashboard">
                      <img
                        src={logo}
                        alt=""
                        height="90"
                        className="svg-color"
                      />
                    </Link>
                  </div>

                  <div className="maintenance-cog-icon text-primary pt-4">
                    <i className="mdi mdi-cog spin-right display-3"></i>
                    <i className="mdi mdi-cog spin-left display-4 cog-icon"></i>
                  </div>
                  <h3 className="mt-4">Fetching your data...</h3>
                  <p>Please wait.</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LoadingPage;
