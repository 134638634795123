import * as url from "../../../helpers/url_helper";
import React, { FC, useContext } from "react";
import { Alert, Button, Form, Input, Label } from "reactstrap";

import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import assert from "assert";
import { firePreLoader } from "../Admin/Admin";
import {
  ItemWithKnownData,
  OmegaResponse,
  QRData,
  QREvent,
} from "src/common/Siemprefy";
import { SiemprefyTextInput } from "src/pages/Utility/SiemprefyTextInput";
import { SiemprefyDateInput } from "src/pages/Utility/SiemprefyDateInput";
import { SiemprefyToggleInput } from "src/pages/Utility/SiemprefyToggleInput";
import { SiemprefyDropdownInput } from "src/pages/Utility/SiemprefyDropdownInput";
import { allIcons } from "./EventIcons";
import { get } from "src/helpers/api_helper";
import { setDataSchema } from "src/pages/Utility/DataSchemaHelper";
import { EventDirections } from "./QREvents";
import { putEvent, removeEvent } from "src/store/siemprefy/actions";
import SiemprefyContext from "src/common/Context3nvoy";
import { SiemprefyFileUploadInput } from "src/pages/Utility/SiemprefyFileUploadInput";

export type FileUploadResponse = {
  statusCode: number;
  result: string;
};

export type AddEditEventForm = {
  itemId: string | undefined;
  event: QREvent | undefined;
  onSubmit: () => void;
};

const AddEditEventForm: FC<AddEditEventForm> = (props) => {
  document.title = "Manage Events | Siemprefy";

  const dispatch = useDispatch();
  const { getAccessToken } = useContext(SiemprefyContext);
  const removeSelectedEvent = async () => {
    const productId = process.env.REACT_APP_PRODUCTID;

    assert(productId, "ProductId is defined");
    assert(props.event, "Event must be selected");

    dispatch(removeEvent(props.event.qrEventId, getAccessToken()));
  };

  const initialValues = {
    title: props.event ? props.event.title : "",
    description: props.event ? props.event.description : "",
    date: props.event
      ? new Date(props.event.date).toISOString().split("T")[0]
      : "",
    direction: props.event ? props.event.direction : EventDirections.Left,
    icon: props.event ? props.event.icon : "",
    useImage: props.event ? (props.event.videoUrl ? true : false) : false,
    imageUrl: props.event ? props.event.imageUrl : "",
    useVideo: props.event ? (props.event.videoUrl ? true : false) : false,
    videoUrl: props.event ? props.event.videoUrl : "",
    videoTitle: props.event ? props.event.videoTitle : "",
  };
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues,
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Title"),
      description: Yup.string().optional(),
      date: Yup.date().required("Please Enter Date of Birth"),
      // icon: Yup.string().required("Please Select Icon"),
      imageUrl: Yup.string().optional(),
      videoUrl: Yup.string().optional(),
      videoTitle: Yup.string().when("videoUrl", {
        is: true,
        then: Yup.string().required("Please Add Video Title"),
      }),
    }),
    onSubmit: async (values, actions) => {
      firePreLoader(5000);
      const productId = process.env.REACT_APP_PRODUCTID;

      assert(productId, "ProductId is defined");

      const config = {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
          productid: productId,
        },
      };

      if (props.event) {
        // Edit
        const response: OmegaResponse<
          Array<ItemWithKnownData<QRData | QREvent>>
        > = await get(`${url.ITEM}/${props.itemId}`, config);
        const itemToUpdate = response.result[0];

        assert(itemToUpdate.itemId, "ItemId is missing!");

        setDataSchema("qrEvent", itemToUpdate, {
          qrEventId: props.event.qrEventId,
          title: values.title,
          description: values.description,
          date: values.date,
          direction: values.direction,
          icon: values.icon,
          imageUrl: values.imageUrl,
          videoUrl: values.videoUrl,
          videoTitle: values.videoTitle,
        });

        const productId = process.env.REACT_APP_PRODUCTID;
        assert(productId, "ProductId is defined");

        dispatch(
          putEvent({
            itemId: itemToUpdate.itemId,
            url: url.ITEM,
            itemToUpdate,
            config,
            accessToken: getAccessToken(),
          })
        );
      } else {
        // Create
        const response: OmegaResponse<
          Array<ItemWithKnownData<QRData | QREvent>>
        > = await get(`${url.ITEM}/${props.itemId}`, config);
        const itemToUpdate = response.result[0];

        assert(itemToUpdate.itemId, "ItemId is missing!");

        setDataSchema("qrEvent", itemToUpdate, {
          title: values.title,
          description: values.description,
          date: values.date,
          direction: values.direction,
          icon: values.icon,
          imageUrl: values.imageUrl,
          videoUrl: values.videoUrl,
          videoTitle: values.videoTitle,
        });

        const productId = process.env.REACT_APP_PRODUCTID;
        assert(productId, "ProductId is defined");

        dispatch(
          putEvent({
            itemId: itemToUpdate.itemId,
            url: url.ITEM,
            itemToUpdate,
            config,
            accessToken: getAccessToken(),
          })
        );
      }

      // Reset the state of the form values
      actions.resetForm();

      props.onSubmit();
    },
  });

  const { error } = useSelector((state: any) => ({
    error: state && state.login && state.login.error,
  }));

  return (
    <>
      {error ? <Alert color="danger">{error}</Alert> : null}
      <Form
        className="custom-form"
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
      >
        <div className="mb-3">
          <SiemprefyTextInput
            label="Title"
            name="title"
            validation={validation}
          ></SiemprefyTextInput>
          <SiemprefyTextInput
            label="Description"
            name="description"
            validation={validation}
          ></SiemprefyTextInput>
          <SiemprefyDateInput
            label="Date Event"
            name="date"
            validation={validation}
          ></SiemprefyDateInput>
          <SiemprefyToggleInput
            label="Direction (Big Displays Only)"
            name="direction"
            options={[
              {
                label: "Left",
                value: "left",
              },
              {
                label: "Right",
                value: "right",
              },
            ]}
            validation={validation}
          ></SiemprefyToggleInput>
          <SiemprefyDropdownInput
            label="Icon"
            name="icon"
            options={allIcons}
            validation={validation}
          ></SiemprefyDropdownInput>
          <hr />
          <div className="mb-3">
            <SiemprefyFileUploadInput
              accessToken={getAccessToken()}
              label={"Event Image"}
              name="imageUrl"
              validation={validation}
            ></SiemprefyFileUploadInput>
          </div>
          <div className="mb-3">
            <Label className="form-label">Event Image Preview</Label>
            {validation.values.imageUrl === null ||
            validation.values.imageUrl === "" ? (
              <p>No image selected</p>
            ) : (
              <>
                <br></br>
                <img
                  src={validation.values.imageUrl}
                  alt=""
                  className="img-fluid rounded timeline-media"
                />
              </>
            )}
          </div>
          <div className="mb-3">
            <SiemprefyFileUploadInput
              accessToken={getAccessToken()}
              label={"Event Video"}
              name="videoUrl"
              validation={validation}
            ></SiemprefyFileUploadInput>
          </div>
          <div className="mb-3">
            <Label className="form-label">Event Video Preview</Label>
            {validation.values.videoUrl === null ||
            validation.values.videoUrl === "" ? (
              <p>No video selected</p>
            ) : (
              <>
                <div className="embed-responsive embed-responsive-21by9 ratio ratio-21x9 timeline-media">
                  <video controls title={validation.values.videoTitle}>
                    <source src={validation.values.videoUrl} type="video/mp4" />
                  </video>
                </div>
              </>
            )}
          </div>
          {validation.values.videoUrl === null ||
          validation.values.videoUrl === "" ? null : (
            <SiemprefyTextInput
              label="Video Title"
              name="videoTitle"
              validation={validation}
            ></SiemprefyTextInput>
          )}
          <div className="event-buttons">
            <Button color="primary" type="submit">
              {props.event ? "Update" : "Create"} Event
            </Button>
            <Button
              color="warning"
              type="button"
              disabled={!props.event}
              onClick={() => {
                validation.setFieldValue("useVideo", false);
                validation.setFieldValue("videoUrl", "");
              }}
            >
              Remove Video
            </Button>
            <Button
              color="warning"
              type="button"
              disabled={!props.event}
              onClick={() => {
                validation.setFieldValue("useImage", false);
                validation.setFieldValue("imageUrl", "");
              }}
            >
              Remove Image
            </Button>
            <Button
              color="danger"
              type="button"
              disabled={!props.event}
              onClick={() => {
                removeSelectedEvent();
                props.onSubmit();
              }}
            >
              Remove Event
            </Button>
            <Button
              color="primary"
              type="button"
              disabled={!props.event}
              onClick={() => {
                props.onSubmit();
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Form>
    </>
  );
};

export default AddEditEventForm;
