import { useAuth0, User } from "@auth0/auth0-react";
import React, { PropsWithChildren, useState } from "react";
import { assertIsDefined } from "./assert";

export interface SiemprefyContext {
  planId: string;
  getAccessToken: () => string;
  setAccessToken: (key: string) => void;
  fetchAccessToken: () => Promise<string>;
  user: User | undefined;
  login: () => void;
}

const paypalPlanId = process.env.REACT_APP_PAYPAL_PLANID as string;

assertIsDefined(paypalPlanId, "PlanId is missing!");

export const initalContextValue: SiemprefyContext = {
  planId: paypalPlanId,
  getAccessToken: () => "00000000-0000-0000-0000-000000000000",
  setAccessToken: (key: string) => {},
  fetchAccessToken: () => Promise.resolve(""),
  user: undefined,
  login: () => {},
};

const SiemprefyContext = React.createContext<SiemprefyContext>(
  initalContextValue
);

export const SiemprefyContextProvider = ({
  children,
}: PropsWithChildren<{}>) => {
  const [siemprefyContext, setSiemprefyContext] = useState({
    ...initalContextValue,
    getAccessToken: () => {
      const token = localStorage.getItem("accessToken") as string;
      return token === null ? "00000000-0000-0000-0000-000000000000" : token;
    },
    setAccessToken: (accessToken: string) => {
      return localStorage.setItem("accessToken", accessToken);
    },
  });

  return (
    <SiemprefyContext.Provider value={siemprefyContext}>
      {children}
    </SiemprefyContext.Provider>
  );
};
export default SiemprefyContext;
