import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { configureStore } from "./store/index";
import { Auth0Provider } from "@auth0/auth0-react";

const getId: any = document.getElementById("root");
const root = ReactDOM.createRoot(getId);

root.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN as string}
    clientId={process.env.REACT_APP_AUTH0_CLIENTID as string}
    audience={process.env.REACT_APP_AUTH0_AUDIENCE as string}
    redirectUri={process.env.REACT_APP_AUTH0_REDIRECTURI as string}
    useRefreshTokens={true}
  >
    <Provider store={configureStore({})}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </Auth0Provider>
);
