import React from "react";
import { useSelector } from "react-redux";
import { Alert, Col, Container, Row } from "reactstrap";
import { QRSubscriptionView } from "src/common/Siemprefy";
import { RootState } from "src/store/state";

export const ContactOwner = () => {
  document.title = `Contact Owner | Siemprefy`;

  const subscription: QRSubscriptionView = useSelector(
    (state: RootState) => state.Siemprefy.subscriptionForTimeline
  );

  return (
    <>
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col className="col-xxl-12 col-lg-12 col-md-12">
              <div className="auth-full-page-content d-flex p-sm-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="auth-content my-auto">
                      <div className="text-center">
                        <h5 className="mb-0">Contact Owner</h5>
                      </div>
                      <form className="custom-form mt-4">
                        <div className="mb-3">
                          <Alert color="danger">
                            There is an issue with this subscription.{" "}
                            {subscription &&
                              subscription.email &&
                              `Please
                            contact ${
                              subscription && subscription.email
                            }`}
                          </Alert>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ContactOwner;
