export const allIcons = [
  { name: "icon", icon: "bx bx-sticker", label: "" },
  { name: "icon", icon: "bx bx-shield-quarter", label: "" },
  { name: "icon", icon: "bx bx-upside-down", label: "" },
  { name: "icon", icon: "bx bx-laugh", label: "" },
  { name: "icon", icon: "bx bx-meh-blank", label: "" },
  { name: "icon", icon: "bx bx-happy-beaming", label: "" },
  { name: "icon", icon: "bx bx-shocked", label: "" },
  { name: "icon", icon: "bx bx-sleepy", label: "" },
  { name: "icon", icon: "bx bx-confused", label: "" },
  { name: "icon", icon: "bx bx-wink-smile", label: "" },
  { name: "icon", icon: "bx bx-dizzy", label: "" },
  { name: "icon", icon: "bx bx-happy-heart-eyes", label: "" },
  { name: "icon", icon: "bx bx-angry", label: "" },
  { name: "icon", icon: "bx bx-smile", label: "" },
  { name: "icon", icon: "bx bx-tired", label: "" },
  { name: "icon", icon: "bx bx-cool", label: "" },
  { name: "icon", icon: "bx bx-happy-alt", label: "" },
  { name: "icon", icon: "bx bx-wink-tongue", label: "" },
  { name: "icon", icon: "bx bx-meh-alt", label: "" },
  { name: "icon", icon: "bx bx-food-menu", label: "" },
  { name: "icon", icon: "bx bx-food-tag", label: "" },
  { name: "icon", icon: "bx bx-female-sign", label: "" },
  { name: "icon", icon: "bx bx-male-sign", label: "" },
  { name: "icon", icon: "bx bx-female", label: "" },
  { name: "icon", icon: "bx bx-male", label: "" },
  { name: "icon", icon: "bx bx-clinic", label: "" },
  { name: "icon", icon: "bx bx-health", label: "" },
  { name: "icon", icon: "bx bx-shekel", label: "" },
  { name: "icon", icon: "bx bx-yen", label: "" },
  { name: "icon", icon: "bx bx-won", label: "" },
  { name: "icon", icon: "bx bx-pound", label: "" },
  { name: "icon", icon: "bx bx-euro", label: "" },
  { name: "icon", icon: "bx bx-rupee", label: "" },
  { name: "icon", icon: "bx bx-ruble", label: "" },
  { name: "icon", icon: "bx bx-lira", label: "" },
  { name: "icon", icon: "bx bx-bitcoin", label: "" },
  { name: "icon", icon: "bx bx-tone", label: "" },
  { name: "icon", icon: "bx bx-bolt-circle", label: "" },
  { name: "icon", icon: "bx bx-cake", label: "" },
  { name: "icon", icon: "bx bx-spa", label: "" },
  { name: "icon", icon: "bx bx-dish", label: "" },
  { name: "icon", icon: "bx bx-fridge", label: "" },
  { name: "icon", icon: "bx bx-image-add", label: "" },
  { name: "icon", icon: "bx bx-image-alt", label: "" },
  { name: "icon", icon: "bx bx-space-bar", label: "" },
  { name: "icon", icon: "bx bx-alarm-add", label: "" },
  { name: "icon", icon: "bx bx-archive-out", label: "" },
  { name: "icon", icon: "bx bx-archive-in", label: "" },
  { name: "icon", icon: "bx bx-add-to-queue", label: "" },
  { name: "icon", icon: "bx bx-border-radius", label: "" },
  { name: "icon", icon: "bx bx-check-shield", label: "" },
  { name: "icon", icon: "bx bx-label", label: "" },
  { name: "icon", icon: "bx bx-file-find", label: "" },
  { name: "icon", icon: "bx bx-face", label: "" },
  { name: "icon", icon: "bx bx-extension", label: "" },
  { name: "icon", icon: "bx bx-exit", label: "" },
  { name: "icon", icon: "bx bx-conversation", label: "" },
  { name: "icon", icon: "bx bx-sort-z-a", label: "" },
  { name: "icon", icon: "bx bx-sort-a-z", label: "" },
  { name: "icon", icon: "bx bx-printer", label: "" },
  { name: "icon", icon: "bx bx-radio", label: "" },
  { name: "icon", icon: "bx bx-customize", label: "" },
  { name: "icon", icon: "bx bx-brush-alt", label: "" },
  { name: "icon", icon: "bx bx-briefcase-alt-2", label: "" },
  { name: "icon", icon: "bx bx-time-five", label: "" },
  { name: "icon", icon: "bx bx-pie-chart-alt-2", label: "" },
  { name: "icon", icon: "bx bx-gas-pump", label: "" },
  { name: "icon", icon: "bx bx-mobile-vibration", label: "" },
  { name: "icon", icon: "bx bx-mobile-landscape", label: "" },
  { name: "icon", icon: "bx bx-border-all", label: "" },
  { name: "icon", icon: "bx bx-border-bottom", label: "" },
  { name: "icon", icon: "bx bx-border-top", label: "" },
  { name: "icon", icon: "bx bx-border-left", label: "" },
  { name: "icon", icon: "bx bx-border-right", label: "" },
  { name: "icon", icon: "bx bx-dialpad-alt", label: "" },
  { name: "icon", icon: "bx bx-filter-alt", label: "" },
  { name: "icon", icon: "bx bx-brightness", label: "" },
  { name: "icon", icon: "bx bx-brightness-half", label: "" },
  { name: "icon", icon: "bx bx-wifi-off", label: "" },
  { name: "icon", icon: "bx bx-credit-card-alt", label: "" },
  { name: "icon", icon: "bx bx-band-aid", label: "" },
  { name: "icon", icon: "bx bx-hive", label: "" },
  { name: "icon", icon: "bx bx-map-pin", label: "" },
  { name: "icon", icon: "bx bx-line-chart", label: "" },
  { name: "icon", icon: "bx bx-receipt", label: "" },
  { name: "icon", icon: "bx bx-purchase-tag-alt", label: "" },
  { name: "icon", icon: "bx bx-basket", label: "" },
  { name: "icon", icon: "bx bx-palette", label: "" },
  { name: "icon", icon: "bx bx-no-entry", label: "" },
  { name: "icon", icon: "bx bx-message-alt-dots", label: "" },
  { name: "icon", icon: "bx bx-message-alt", label: "" },
  { name: "icon", icon: "bx bx-check-square", label: "" },
  { name: "icon", icon: "bx bx-log-out-circle", label: "" },
  { name: "icon", icon: "bx bx-log-in-circle", label: "" },
  { name: "icon", icon: "bx bx-doughnut-chart", label: "" },
  { name: "icon", icon: "bx bx-building-house", label: "" },
  { name: "icon", icon: "bx bx-accessibility", label: "" },
  { name: "icon", icon: "bx bx-user-voice", label: "" },
  { name: "icon", icon: "bx bx-cuboid", label: "" },
  { name: "icon", icon: "bx bx-cube-alt", label: "" },
  { name: "icon", icon: "bx bx-polygon", label: "" },
  { name: "icon", icon: "bx bx-square-rounded", label: "" },
  { name: "icon", icon: "bx bx-square", label: "" },
  { name: "icon", icon: "bx bx-error-alt", label: "" },
  { name: "icon", icon: "bx bx-shield-alt-2", label: "" },
  { name: "icon", icon: "bx bx-paint-roll", label: "" },
  { name: "icon", icon: "bx bx-droplet", label: "" },
  { name: "icon", icon: "bx bx-street-view", label: "" },
  { name: "icon", icon: "bx bx-plus-medical", label: "" },
  { name: "icon", icon: "bx bx-search-alt-2", label: "" },
  { name: "icon", icon: "bx bx-bowling-ball", label: "" },
  { name: "icon", icon: "bx bx-dna", label: "" },
  { name: "icon", icon: "bx bx-cycling", label: "" },
  { name: "icon", icon: "bx bx-shape-circle", label: "" },
  { name: "icon", icon: "bx bx-down-arrow-alt", label: "" },
  { name: "icon", icon: "bx bx-up-arrow-alt", label: "" },
  { name: "icon", icon: "bx bx-right-arrow-alt", label: "" },
  { name: "icon", icon: "bx bx-left-arrow-alt", label: "" },
  { name: "icon", icon: "bx bx-lock-open-alt", label: "" },
  { name: "icon", icon: "bx bx-lock-alt", label: "" },
  { name: "icon", icon: "bx bx-cylinder", label: "" },
  { name: "icon", icon: "bx bx-pyramid", label: "" },
  { name: "icon", icon: "bx bx-comment-dots", label: "" },
  { name: "icon", icon: "bx bx-comment", label: "" },
  { name: "icon", icon: "bx bx-landscape", label: "" },
  { name: "icon", icon: "bx bx-book-open", label: "" },
  { name: "icon", icon: "bx bx-transfer-alt", label: "" },
  { name: "icon", icon: "bx bx-copy-alt", label: "" },
  { name: "icon", icon: "bx bx-run", label: "" },
  { name: "icon", icon: "bx bx-user-pin", label: "" },
  { name: "icon", icon: "bx bx-grid", label: "" },
  { name: "icon", icon: "bx bx-code-alt", label: "" },
  { name: "icon", icon: "bx bx-mail-send", label: "" },
  { name: "icon", icon: "bx bx-ghost", label: "" },
  { name: "icon", icon: "bx bx-shape-triangle", label: "" },
  { name: "icon", icon: "bx bx-shape-square", label: "" },
  { name: "icon", icon: "bx bx-video-recording", label: "" },
  { name: "icon", icon: "bx bx-notepad", label: "" },
  { name: "icon", icon: "bx bx-bug-alt", label: "" },
  { name: "icon", icon: "bx bx-mouse-alt", label: "" },
  { name: "icon", icon: "bx bx-edit-alt", label: "" },
  { name: "icon", icon: "bx bx-chat", label: "" },
  { name: "icon", icon: "bx bx-book-content", label: "" },
  { name: "icon", icon: "bx bx-message-square-dots", label: "" },
  { name: "icon", icon: "bx bx-message-square", label: "" },
  { name: "icon", icon: "bx bx-slideshow", label: "" },
  { name: "icon", icon: "bx bx-wallet-alt", label: "" },
  { name: "icon", icon: "bx bx-memory-card", label: "" },
  { name: "icon", icon: "bx bx-message-rounded-dots", label: "" },
  { name: "icon", icon: "bx bx-message-dots", label: "" },
  { name: "icon", icon: "bx bx-bar-chart-alt-2", label: "" },
  { name: "icon", icon: "bx bx-store-alt", label: "" },
  { name: "icon", icon: "bx bx-buildings", label: "" },
  { name: "icon", icon: "bx bx-home-circle", label: "" },
  { name: "icon", icon: "bx bx-money", label: "" },
  { name: "icon", icon: "bx bx-walk", label: "" },
  { name: "icon", icon: "bx bx-repeat", label: "" },
  { name: "icon", icon: "bx bx-font-family", label: "" },
  { name: "icon", icon: "bx bx-joystick-button", label: "" },
  { name: "icon", icon: "bx bx-paint", label: "" },
  { name: "icon", icon: "bx bx-unlink", label: "" },
  { name: "icon", icon: "bx bx-brush", label: "" },
  { name: "icon", icon: "bx bx-rotate-left", label: "" },
  { name: "icon", icon: "bx bx-badge-check", label: "" },
  { name: "icon", icon: "bx bx-show-alt", label: "" },
  { name: "icon", icon: "bx bx-caret-down", label: "" },
  { name: "icon", icon: "bx bx-caret-right", label: "" },
  { name: "icon", icon: "bx bx-caret-up", label: "" },
  { name: "icon", icon: "bx bx-caret-left", label: "" },
  { name: "icon", icon: "bx bx-calendar-event", label: "" },
  { name: "icon", icon: "bx bx-magnet", label: "" },
  { name: "icon", icon: "bx bx-rewind-circle", label: "" },
  { name: "icon", icon: "bx bx-card", label: "" },
  { name: "icon", icon: "bx bx-help-circle", label: "" },
  { name: "icon", icon: "bx bx-test-tube", label: "" },
  { name: "icon", icon: "bx bx-note", label: "" },
  { name: "icon", icon: "bx bx-sort-down", label: "" },
  { name: "icon", icon: "bx bx-sort-up", label: "" },
  { name: "icon", icon: "bx bx-id-card", label: "" },
  { name: "icon", icon: "bx bx-badge", label: "" },
  { name: "icon", icon: "bx bx-grid-small", label: "" },
  { name: "icon", icon: "bx bx-grid-vertical", label: "" },
  { name: "icon", icon: "bx bx-grid-horizontal", label: "" },
  { name: "icon", icon: "bx bx-move-vertical", label: "" },
  { name: "icon", icon: "bx bx-move-horizontal", label: "" },
  { name: "icon", icon: "bx bx-stats", label: "" },
  { name: "icon", icon: "bx bx-equalizer", label: "" },
  { name: "icon", icon: "bx bx-disc", label: "" },
  { name: "icon", icon: "bx bx-analyse", label: "" },
  { name: "icon", icon: "bx bx-search-alt", label: "" },
  { name: "icon", icon: "bx bx-dollar-circle", label: "" },
  { name: "icon", icon: "bx bx-football", label: "" },
  { name: "icon", icon: "bx bx-ball", label: "" },
  { name: "icon", icon: "bx bx-circle", label: "" },
  { name: "icon", icon: "bx bx-transfer", label: "" },
  { name: "icon", icon: "bx bx-fingerprint", label: "" },
  { name: "icon", icon: "bx bx-font-color", label: "" },
  { name: "icon", icon: "bx bx-highlight", label: "" },
  { name: "icon", icon: "bx bx-file-blank", label: "" },
  { name: "icon", icon: "bx bx-strikethrough", label: "" },
  { name: "icon", icon: "bx bx-photo-album", label: "" },
  { name: "icon", icon: "bx bx-code-block", label: "" },
  { name: "icon", icon: "bx bx-font-size", label: "" },
  { name: "icon", icon: "bx bx-handicap", label: "" },
  { name: "icon", icon: "bx bx-dialpad", label: "" },
  { name: "icon", icon: "bx bx-wind", label: "" },
  { name: "icon", icon: "bx bx-water", label: "" },
  { name: "icon", icon: "bx bx-swim", label: "" },
  { name: "icon", icon: "bx bx-restaurant", label: "" },
  { name: "icon", icon: "bx bx-box", label: "" },
  { name: "icon", icon: "bx bx-menu-alt-right", label: "" },
  { name: "icon", icon: "bx bx-menu-alt-left", label: "" },
  { name: "icon", icon: "bx bx-video-plus", label: "" },
  { name: "icon", icon: "bx bx-list-ol", label: "" },
  { name: "icon", icon: "bx bx-planet", label: "" },
  { name: "icon", icon: "bx bx-hotel", label: "" },
  { name: "icon", icon: "bx bx-movie", label: "" },
  { name: "icon", icon: "bx bx-taxi", label: "" },
  { name: "icon", icon: "bx bx-train", label: "" },
  { name: "icon", icon: "bx bx-bath", label: "" },
  { name: "icon", icon: "bx bx-bed", label: "" },
  { name: "icon", icon: "bx bx-area", label: "" },
  { name: "icon", icon: "bx bx-bot", label: "" },
  { name: "icon", icon: "bx bx-dumbbell", label: "" },
  { name: "icon", icon: "bx bx-check-double", label: "" },
  { name: "icon", icon: "bx bx-bus", label: "" },
  { name: "icon", icon: "bx bx-check-circle", label: "" },
  { name: "icon", icon: "bx bx-rocket", label: "" },
  { name: "icon", icon: "bx bx-certification", label: "" },
  { name: "icon", icon: "bx bx-slider-alt", label: "" },
  { name: "icon", icon: "bx bx-sad", label: "" },
  { name: "icon", icon: "bx bx-meh", label: "" },
  { name: "icon", icon: "bx bx-happy", label: "" },
  { name: "icon", icon: "bx bx-cart-alt", label: "" },
  { name: "icon", icon: "bx bx-car", label: "" },
  { name: "icon", icon: "bx bx-loader-alt", label: "" },
  { name: "icon", icon: "bx bx-loader-circle", label: "" },
  { name: "icon", icon: "bx bx-wrench", label: "" },
  { name: "icon", icon: "bx bx-alarm-off", label: "" },
  { name: "icon", icon: "bx bx-layout", label: "" },
  { name: "icon", icon: "bx bx-dock-left", label: "" },
  { name: "icon", icon: "bx bx-dock-top", label: "" },
  { name: "icon", icon: "bx bx-dock-right", label: "" },
  { name: "icon", icon: "bx bx-dock-bottom", label: "" },
  { name: "icon", icon: "bx bx-dock-bottom", label: "" },
  { name: "icon", icon: "bx bx-world", label: "" },
  { name: "icon", icon: "bx bx-selection", label: "" },
  { name: "icon", icon: "bx bx-paper-plane", label: "" },
  { name: "icon", icon: "bx bx-slider", label: "" },
  { name: "icon", icon: "bx bx-loader", label: "" },
  { name: "icon", icon: "bx bx-chalkboard", label: "" },
  { name: "icon", icon: "bx bx-trash-alt", label: "" },
  { name: "icon", icon: "bx bx-grid-alt", label: "" },
  { name: "icon", icon: "bx bx-command", label: "" },
  { name: "icon", icon: "bx bx-window-close", label: "" },
  { name: "icon", icon: "bx bx-notification-off", label: "" },
  { name: "icon", icon: "bx bx-plug", label: "" },
  { name: "icon", icon: "bx bx-infinite", label: "" },
  { name: "icon", icon: "bx bx-carousel", label: "" },
  { name: "icon", icon: "bx bx-hourglass", label: "" },
  { name: "icon", icon: "bx bx-briefcase-alt", label: "" },
  { name: "icon", icon: "bx bx-wallet", label: "" },
  { name: "icon", icon: "bx bx-station", label: "" },
  { name: "icon", icon: "bx bx-collection", label: "" },
  { name: "icon", icon: "bx bx-tv", label: "" },
  { name: "icon", icon: "bx bx-closet", label: "" },
  { name: "icon", icon: "bx bx-paperclip", label: "" },
  { name: "icon", icon: "bx bx-expand", label: "" },
  { name: "icon", icon: "bx bx-pen", label: "" },
  { name: "icon", icon: "bx bx-purchase-tag", label: "" },
  { name: "icon", icon: "bx bx-images", label: "" },
  { name: "icon", icon: "bx bx-pie-chart-alt", label: "" },
  { name: "icon", icon: "bx bx-news", label: "" },
  { name: "icon", icon: "bx bx-downvote", label: "" },
  { name: "icon", icon: "bx bx-upvote", label: "" },
  { name: "icon", icon: "bx bx-globe-alt", label: "" },
  { name: "icon", icon: "bx bx-store", label: "" },
  { name: "icon", icon: "bx bx-hdd", label: "" },
  { name: "icon", icon: "bx bx-skip-previous-circle", label: "" },
  { name: "icon", icon: "bx bx-skip-next-circle", label: "" },
  { name: "icon", icon: "bx bx-chip", label: "" },
  { name: "icon", icon: "bx bx-cast", label: "" },
  { name: "icon", icon: "bx bx-body", label: "" },
  { name: "icon", icon: "bx bx-phone-outgoing", label: "" },
  { name: "icon", icon: "bx bx-phone-incoming", label: "" },
  { name: "icon", icon: "bx bx-collapse", label: "" },
  { name: "icon", icon: "bx bx-rename", label: "" },
  { name: "icon", icon: "bx bx-rotate-right", label: "" },
  { name: "icon", icon: "bx bx-horizontal-center", label: "" },
  { name: "icon", icon: "bx bx-ruler", label: "" },
  { name: "icon", icon: "bx bx-import", label: "" },
  { name: "icon", icon: "bx bx-calendar-alt", label: "" },
  { name: "icon", icon: "bx bx-battery", label: "" },
  { name: "icon", icon: "bx bx-server", label: "" },
  { name: "icon", icon: "bx bx-task", label: "" },
  { name: "icon", icon: "bx bx-folder-open", label: "" },
  { name: "icon", icon: "bx bx-film", label: "" },
  { name: "icon", icon: "bx bx-aperture", label: "" },
  { name: "icon", icon: "bx bx-phone-call", label: "" },
  { name: "icon", icon: "bx bx-up-arrow", label: "" },
  { name: "icon", icon: "bx bx-undo", label: "" },
  { name: "icon", icon: "bx bx-timer", label: "" },
  { name: "icon", icon: "bx bx-support", label: "" },
  { name: "icon", icon: "bx bx-subdirectory-right", label: "" },
  { name: "icon", icon: "bx bx-right-arrow", label: "" },
  { name: "icon", icon: "bx bx-revision", label: "" },
  { name: "icon", icon: "bx bx-repost", label: "" },
  { name: "icon", icon: "bx bx-reply", label: "" },
  { name: "icon", icon: "bx bx-reply-all", label: "" },
  { name: "icon", icon: "bx bx-redo", label: "" },
  { name: "icon", icon: "bx bx-radar", label: "" },
  { name: "icon", icon: "bx bx-poll", label: "" },
  { name: "icon", icon: "bx bx-list-check", label: "" },
  { name: "icon", icon: "bx bx-like", label: "" },
  { name: "icon", icon: "bx bx-left-arrow", label: "" },
  { name: "icon", icon: "bx bx-joystick-alt", label: "" },
  { name: "icon", icon: "bx bx-history", label: "" },
  { name: "icon", icon: "bx bx-flag", label: "" },
  { name: "icon", icon: "bx bx-first-aid", label: "" },
  { name: "icon", icon: "bx bx-export", label: "" },
  { name: "icon", icon: "bx bx-down-arrow", label: "" },
  { name: "icon", icon: "bx bx-dislike", label: "" },
  { name: "icon", icon: "bx bx-crown", label: "" },
  { name: "icon", icon: "bx bx-barcode", label: "" },
  { name: "icon", icon: "bx bx-user", label: "" },
  { name: "icon", icon: "bx bx-user-x", label: "" },
  { name: "icon", icon: "bx bx-user-plus", label: "" },
  { name: "icon", icon: "bx bx-user-minus", label: "" },
  { name: "icon", icon: "bx bx-user-circle", label: "" },
  { name: "icon", icon: "bx bx-user-check", label: "" },
  { name: "icon", icon: "bx bx-underline", label: "" },
  { name: "icon", icon: "bx bx-trophy", label: "" },
  { name: "icon", icon: "bx bx-trash", label: "" },
  { name: "icon", icon: "bx bx-text", label: "" },
  { name: "icon", icon: "bx bx-sun", label: "" },
  { name: "icon", icon: "bx bx-star", label: "" },
  { name: "icon", icon: "bx bx-sort", label: "" },
  { name: "icon", icon: "bx bx-shuffle", label: "" },
  { name: "icon", icon: "bx bx-shopping-bag", label: "" },
  { name: "icon", icon: "bx bx-shield", label: "" },
  { name: "icon", icon: "bx bx-shield-alt", label: "" },
  { name: "icon", icon: "bx bx-share", label: "" },
  { name: "icon", icon: "bx bx-share-alt", label: "" },
  { name: "icon", icon: "bx bx-select-multiple", label: "" },
  { name: "icon", icon: "bx bx-screenshot", label: "" },
  { name: "icon", icon: "bx bx-save", label: "" },
  { name: "icon", icon: "bx bx-pulse", label: "" },
  { name: "icon", icon: "bx bx-power-off", label: "" },
  { name: "icon", icon: "bx bx-plus", label: "" },
  { name: "icon", icon: "bx bx-pin", label: "" },
  { name: "icon", icon: "bx bx-pencil", label: "" },
  { name: "icon", icon: "bx bx-pin", label: "" },
  { name: "icon", icon: "bx bx-pencil", label: "" },
  { name: "icon", icon: "bx bx-paste", label: "" },
  { name: "icon", icon: "bx bx-paragraph", label: "" },
  { name: "icon", icon: "bx bx-package", label: "" },
  { name: "icon", icon: "bx bx-notification", label: "" },
  { name: "icon", icon: "bx bx-music", label: "" },
  { name: "icon", icon: "bx bx-move", label: "" },
  { name: "icon", icon: "bx bx-mouse", label: "" },
  { name: "icon", icon: "bx bx-minus", label: "" },
  { name: "icon", icon: "bx bx-microphone-off", label: "" },
  { name: "icon", icon: "bx bx-log-out", label: "" },
  { name: "icon", icon: "bx bx-log-in", label: "" },
  { name: "icon", icon: "bx bx-link-external", label: "" },
  { name: "icon", icon: "bx bx-joystick", label: "" },
  { name: "icon", icon: "bx bx-italic", label: "" },
  { name: "icon", icon: "bx bx-home-alt", label: "" },
  { name: "icon", icon: "bx bx-heading", label: "" },
  { name: "icon", icon: "bx bx-hash", label: "" },
  { name: "icon", icon: "bx bx-group", label: "" },
  { name: "icon", icon: "bx bx-git-repo-forked", label: "" },
  { name: "icon", icon: "bx bx-git-pull-request", label: "" },
  { name: "icon", icon: "bx bx-git-merge", label: "" },
  { name: "icon", icon: "bx bx-git-compare", label: "" },
  { name: "icon", icon: "bx bx-git-commit", label: "" },
  { name: "icon", icon: "bx bx-git-branch", label: "" },
  { name: "icon", icon: "bx bx-font", label: "" },
  { name: "icon", icon: "bx bx-filter", label: "" },
  { name: "icon", icon: "bx bx-file", label: "" },
  { name: "icon", icon: "bx bx-edit", label: "" },
  { name: "icon", icon: "bx bx-diamond", label: "" },
  { name: "icon", icon: "bx bx-detail", label: "" },
  { name: "icon", icon: "bx bx-cut", label: "" },
  { name: "icon", icon: "bx bx-cube", label: "" },
  { name: "icon", icon: "bx bx-crop", label: "" },
  { name: "icon", icon: "bx bx-credit-card", label: "" },
  { name: "icon", icon: "bx bx-columns", label: "" },
  { name: "icon", icon: "bx bx-cog", label: "" },
  { name: "icon", icon: "bx bx-cloud-snow", label: "" },
  { name: "icon", icon: "bx bx-cloud-rain", label: "" },
  { name: "icon", icon: "bx bx-cloud-lightning", label: "" },
  { name: "icon", icon: "bx bx-cloud-light-rain", label: "" },
  { name: "icon", icon: "bx bx-cloud-drizzle", label: "" },
  { name: "icon", icon: "bx bx-check", label: "" },
  { name: "icon", icon: "bx bx-cart", label: "" },
  { name: "icon", icon: "bx bx-calculator", label: "" },
  { name: "icon", icon: "bx bx-bold", label: "" },
  { name: "icon", icon: "bx bx-award", label: "" },
  { name: "icon", icon: "bx bx-anchor", label: "" },
  { name: "icon", icon: "bx bx-album", label: "" },
  { name: "icon", icon: "bx bx-adjust", label: "" },
  { name: "icon", icon: "bx bx-x", label: "" },
  { name: "icon", icon: "bx bx-table", label: "" },
  { name: "icon", icon: "bx bx-duplicate", label: "" },
  { name: "icon", icon: "bx bx-windows", label: "" },
  { name: "icon", icon: "bx bx-window", label: "" },
  { name: "icon", icon: "bx bx-window-open", label: "" },
  { name: "icon", icon: "bx bx-wifi", label: "" },
  { name: "icon", icon: "bx bx-voicemail", label: "" },
  { name: "icon", icon: "bx bx-video-off", label: "" },
  { name: "icon", icon: "bx bx-usb", label: "" },
  { name: "icon", icon: "bx bx-upload", label: "" },
  { name: "icon", icon: "bx bx-alarm", label: "" },
  { name: "icon", icon: "bx bx-tennis-ball", label: "" },
  { name: "icon", icon: "bx bx-target-lock", label: "" },
  { name: "icon", icon: "bx bx-tag", label: "" },
  { name: "icon", icon: "bx bx-tab", label: "" },
  { name: "icon", icon: "bx bx-spreadsheet", label: "" },
  { name: "icon", icon: "bx bx-sitemap", label: "" },
  { name: "icon", icon: "bx bx-sidebar", label: "" },
  { name: "icon", icon: "bx bx-send", label: "" },
  { name: "icon", icon: "bx bx-pie-chart", label: "" },
  { name: "icon", icon: "bx bx-phone", label: "" },
  { name: "icon", icon: "bx bx-navigation", label: "" },
  { name: "icon", icon: "bx bx-mobile", label: "" },
  { name: "icon", icon: "bx bx-mobile-alt", label: "" },
  { name: "icon", icon: "bx bx-message", label: "" },
  { name: "icon", icon: "bx bx-message-rounded", label: "" },
  { name: "icon", icon: "bx bx-map", label: "" },
  { name: "icon", icon: "bx bx-map-alt", label: "" },
  { name: "icon", icon: "bx bx-lock", label: "" },
  { name: "icon", icon: "bx bx-lock-open", label: "" },
  { name: "icon", icon: "bx bx-list-minus", label: "" },
  { name: "icon", icon: "bx bx-list-ul", label: "" },
  { name: "icon", icon: "bx bx-list-plus", label: "" },
  { name: "icon", icon: "bx bx-link", label: "" },
  { name: "icon", icon: "bx bx-link-alt", label: "" },
  { name: "icon", icon: "bx bx-layer", label: "" },
  { name: "icon", icon: "bx bx-laptop", label: "" },
  { name: "icon", icon: "bx bx-home", label: "" },
  { name: "icon", icon: "bx bx-heart", label: "" },
  { name: "icon", icon: "bx bx-headphone", label: "" },
  { name: "icon", icon: "bx bx-devices", label: "" },
  { name: "icon", icon: "bx bx-globe", label: "" },
  { name: "icon", icon: "bx bx-gift", label: "" },
  { name: "icon", icon: "bx bx-envelope", label: "" },
  { name: "icon", icon: "bx bx-download", label: "" },
  { name: "icon", icon: "bx bx-dots-vertical", label: "" },
  { name: "icon", icon: "bx bx-dots-vertical-rounded", label: "" },
  { name: "icon", icon: "bx bx-dots-horizontal", label: "" },
  { name: "icon", icon: "bx bx-dots-horizontal-rounded", label: "" },
  { name: "icon", icon: "bx bx-dollar", label: "" },
  { name: "icon", icon: "bx bx-directions", label: "" },
  { name: "icon", icon: "bx bx-desktop", label: "" },
  { name: "icon", icon: "bx bx-data", label: "" },
  { name: "icon", icon: "bx bx-compass", label: "" },
  { name: "icon", icon: "bx bx-crosshair", label: "" },
  { name: "icon", icon: "bx bx-terminal", label: "" },
  { name: "icon", icon: "bx bx-cloud", label: "" },
  { name: "icon", icon: "bx bx-cloud-upload", label: "" },
  { name: "icon", icon: "bx bx-cloud-download", label: "" },
  { name: "icon", icon: "bx bx-chart", label: "" },
  { name: "icon", icon: "bx bx-calendar", label: "" },
  { name: "icon", icon: "bx bx-calendar-x", label: "" },
  { name: "icon", icon: "bx bx-calendar-minus", label: "" },
  { name: "icon", icon: "bx bx-calendar-check", label: "" },
  { name: "icon", icon: "bx bx-calendar-plus", label: "" },
  { name: "icon", icon: "bx bx-buoy", label: "" },
  { name: "icon", icon: "bx bx-bulb", label: "" },
  { name: "icon", icon: "bx bx-bluetooth", label: "" },
  { name: "icon", icon: "bx bx-bug", label: "" },
  { name: "icon", icon: "bx bx-building", label: "" },
  { name: "icon", icon: "bx bx-broadcast", label: "" },
  { name: "icon", icon: "bx bx-briefcase", label: "" },
  { name: "icon", icon: "bx bx-bookmark-plus", label: "" },
  { name: "icon", icon: "bx bx-bookmark-minus", label: "" },
  { name: "icon", icon: "bx bx-book", label: "" },
  { name: "icon", icon: "bx bx-book-bookmark", label: "" },
  { name: "icon", icon: "bx bx-block", label: "" },
  { name: "icon", icon: "bx bx-basketball", label: "" },
  { name: "icon", icon: "bx bx-bar-chart", label: "" },
  { name: "icon", icon: "bx bx-bar-chart-square", label: "" },
  { name: "icon", icon: "bx bx-bar-chart-alt", label: "" },
  { name: "icon", icon: "bx bx-at", label: "" },
  { name: "icon", icon: "bx bx-archive", label: "" },
  { name: "icon", icon: "bx bx-zoom-out", label: "" },
  { name: "icon", icon: "bx bx-zoom-in", label: "" },
  { name: "icon", icon: "bx bx-x-circle", label: "" },
  { name: "icon", icon: "bx bx-volume", label: "" },
  { name: "icon", icon: "bx bx-volume-mute", label: "" },
  { name: "icon", icon: "bx bx-volume-low", label: "" },
  { name: "icon", icon: "bx bx-volume-full", label: "" },
  { name: "icon", icon: "bx bx-video", label: "" },
  { name: "icon", icon: "bx bx-vertical-center", label: "" },
  { name: "icon", icon: "bx bx-up-arrow-circle", label: "" },
  { name: "icon", icon: "bx bx-trending-up", label: "" },
  { name: "icon", icon: "bx bx-trending-down", label: "" },
  { name: "icon", icon: "bx bx-toggle-right", label: "" },
  { name: "icon", icon: "bx bx-toggle-left", label: "" },
  { name: "icon", icon: "bx bx-time", label: "" },
  { name: "icon", icon: "bx bx-sync", label: "" },
  { name: "icon", icon: "bx bx-stopwatch", label: "" },
  { name: "icon", icon: "bx bx-stop", label: "" },
  { name: "icon", icon: "bx bx-stop-circle", label: "" },
  { name: "icon", icon: "bx bx-skip-previous", label: "" },
  { name: "icon", icon: "bx bx-skip-next", label: "" },
  { name: "icon", icon: "bx bx-show", label: "" },
  { name: "icon", icon: "bx bx-search", label: "" },
  { name: "icon", icon: "bx bx-rss", label: "" },
  { name: "icon", icon: "bx bx-right-top-arrow-circle", label: "" },
  { name: "icon", icon: "bx bx-right-indent", label: "" },
  { name: "icon", icon: "bx bx-right-down-arrow-circle", label: "" },
  { name: "icon", icon: "bx bx-right-arrow-circle", label: "" },
  { name: "icon", icon: "bx bx-reset", label: "" },
  { name: "icon", icon: "bx bx-rewind", label: "" },
  { name: "icon", icon: "bx bx-rectangle", label: "" },
  { name: "icon", icon: "bx bx-radio-circle", label: "" },
  { name: "icon", icon: "bx bx-radio-circle-marked", label: "" },
  { name: "icon", icon: "bx bx-question-mark", label: "" },
  { name: "icon", icon: "bx bx-plus-circle", label: "" },
  { name: "icon", icon: "bx bx-play", label: "" },
  { name: "icon", icon: "bx bx-play-circle", label: "" },
  { name: "icon", icon: "bx bx-pause", label: "" },
  { name: "icon", icon: "bx bx-pause-circle", label: "" },
  { name: "icon", icon: "bx bx-moon", label: "" },
  { name: "icon", icon: "bx bx-minus-circle", label: "" },
  { name: "icon", icon: "bx bx-microphone", label: "" },
  { name: "icon", icon: "bx bx-menu", label: "" },
  { name: "icon", icon: "bx bx-left-top-arrow-circle", label: "" },
  { name: "icon", icon: "bx bx-left-indent", label: "" },
  { name: "icon", icon: "bx bx-left-down-arrow-circle", label: "" },
  { name: "icon", icon: "bx bx-left-arrow-circle", label: "" },
  { name: "icon", icon: "bx bx-last-page", label: "" },
  { name: "icon", icon: "bx bx-key", label: "" },
  { name: "icon", icon: "bx bx-align-justify", label: "" },
  { name: "icon", icon: "bx bx-info-circle", label: "" },
  { name: "icon", icon: "bx bx-image", label: "" },
  { name: "icon", icon: "bx bx-hide", label: "" },
  { name: "icon", icon: "bx bx-fullscreen", label: "" },
  { name: "icon", icon: "bx bx-folder", label: "" },
  { name: "icon", icon: "bx bx-folder-plus", label: "" },
  { name: "icon", icon: "bx bx-folder-minus", label: "" },
  { name: "icon", icon: "bx bx-first-page", label: "" },
  { name: "icon", icon: "bx bx-fast-forward", label: "" },
  { name: "icon", icon: "bx bx-fast-forward-circle", label: "" },
  { name: "icon", icon: "bx bx-exit-fullscreen", label: "" },
  { name: "icon", icon: "bx bx-error", label: "" },
  { name: "icon", icon: "bx bx-error-circle", label: "" },
  { name: "icon", icon: "bx bx-down-arrow-circle", label: "" },
  { name: "icon", icon: "bx bx-copyright", label: "" },
  { name: "icon", icon: "bx bx-copy", label: "" },
  { name: "icon", icon: "bx bx-coffee", label: "" },
  { name: "icon", icon: "bx bx-code", label: "" },
  { name: "icon", icon: "bx bx-code-curly", label: "" },
  { name: "icon", icon: "bx bx-clipboard", label: "" },
  { name: "icon", icon: "bx bx-chevrons-left", label: "" },
  { name: "icon", icon: "bx bx-chevrons-right", label: "" },
  { name: "icon", icon: "bx bx-chevrons-up", label: "" },
  { name: "icon", icon: "bx bx-chevrons-down", label: "" },
  { name: "icon", icon: "bx bx-chevron-right", label: "" },
  { name: "icon", icon: "bx bx-chevron-left", label: "" },
  { name: "icon", icon: "bx bx-chevron-up", label: "" },
  { name: "icon", icon: "bx bx-chevron-down", label: "" },
  { name: "icon", icon: "bx bx-checkbox-square", label: "" },
  { name: "icon", icon: "bx bx-checkbox", label: "" },
  { name: "icon", icon: "bx bx-checkbox-checked", label: "" },
  { name: "icon", icon: "bx bx-captions", label: "" },
  { name: "icon", icon: "bx bx-camera", label: "" },
  { name: "icon", icon: "bx bx-camera-off", label: "" },
  { name: "icon", icon: "bx bx-bullseye", label: "" },
  { name: "icon", icon: "bx bx-bookmarks", label: "" },
  { name: "icon", icon: "bx bx-bookmark", label: "" },
  { name: "icon", icon: "bx bx-bell", label: "" },
  { name: "icon", icon: "bx bx-bell-plus", label: "" },
  { name: "icon", icon: "bx bx-bell-off", label: "" },
  { name: "icon", icon: "bx bx-bell-minus", label: "" },
  { name: "icon", icon: "bx bx-arrow-back", label: "" },
  { name: "icon", icon: "bx bx-align-right", label: "" },
  { name: "icon", icon: "bx bx-align-middle", label: "" },
  { name: "icon", icon: "bx bx-align-lef", label: "" },
];