import React, { useState } from "react";
import { Label, Input, FormFeedback } from "reactstrap";

export interface TextInputProps {
  name: string;
  label: string;
  validation: any;
}

export const SiemprefyBoolInput = (props: TextInputProps) => {
  const startDefaultChecked = props.validation.values[props.name];
  if (startDefaultChecked) {
    return (
      <>
        <Input
          id={props.name}
          name={props.name}
          defaultChecked={startDefaultChecked}
          className="form-check-input"
          placeholder={`Enter ${props.label}`}
          type="checkbox"
          onChange={props.validation.handleChange}
          onBlur={props.validation.handleBlur}
          value={props.validation.values[props.name] || ""}
          invalid={
            props.validation.touched[props.name] &&
            props.validation.errors[props.name]
              ? true
              : false
          }
        />
        <Label
          className="form-check-label"
          htmlFor={props.name}
          style={{ marginLeft: "5px" }}
        >
          {props.label}
        </Label>
        {props.validation.touched[props.name] &&
        props.validation.errors[props.name] ? (
          <FormFeedback type="invalid">
            {props.validation.errors[props.name]}
          </FormFeedback>
        ) : null}
      </>
    );
  } else {
    return (
      <>
        <Input
          id={props.name}
          name={props.name}
          className="form-check-input"
          placeholder={`Enter ${props.label}`}
          type="checkbox"
          onChange={props.validation.handleChange}
          onBlur={props.validation.handleBlur}
          value={props.validation.values[props.name] || ""}
          invalid={
            props.validation.touched[props.name] &&
            props.validation.errors[props.name]
              ? true
              : false
          }
        />
        <Label
          className="form-check-label"
          htmlFor={props.name}
          style={{ marginLeft: "5px" }}
        >
          {props.label}
        </Label>
        {props.validation.touched[props.name] &&
        props.validation.errors[props.name] ? (
          <FormFeedback type="invalid">
            {props.validation.errors[props.name]}
          </FormFeedback>
        ) : null}
      </>
    );
  }
};
