import { Container, Row, Col, Card, CardHeader, CardBody } from "reactstrap";
import { QREvent } from "src/common/Siemprefy";
import { QRTimeline } from "./PageTimeline";
import React, { FC } from "react";

const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export interface SiemprefyTimelineProps {
	timeline: QRTimeline;
}

export const SiemprefyTimeline: FC<SiemprefyTimelineProps> = ({ timeline }) => {
  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h4 className="card-title">
                    {timeline.qrData?.userFirstName}{" "}
                    {timeline.qrData?.userLastName}
                  </h4>
                </CardHeader>

                <CardBody>
                  <Row className="justify-content-center">
                    <Col xl={12}>
                      <div className="timeline">
                        <div className="timeline-container">
                          <p className="user-dob">
                            {timeline.qrData?.userDob.toLocaleDateString()}
                          </p>
                          <div className="timeline-end">
                            <p>Born</p>
                          </div>
                          <div className="timeline-continue">
                            {timeline.qrEvents?.map((event: QREvent, idx) => {
                              return (
                                <Row
                                  key={idx}
                                  className={`timeline-${event.direction}`}
                                >
                                  {event.direction === "left" ? (
                                    <Col
                                      className="timeline-mobile-icon"
                                      md={6}
                                    >
                                      <div className="d-md-block col-md-6">
                                        <div className="timeline-icon">
                                          <i
                                            className={`${event.icon} text-primary h2 mb-0`}
                                          ></i>
                                        </div>
                                      </div>
                                    </Col>
                                  ) : (
                                    <></>
                                  )}
                                  {event.direction === "right" ? (
                                    <Col
                                      className="timeline-column"
                                      md={6}
                                      style={{
                                        justifyContent: "right",
                                      }}
                                    >
                                      <div className="timeline-icon">
                                        <i
                                          className={`${event.icon} text-primary h2 mb-0`}
                                        ></i>
                                      </div>
                                    </Col>
                                  ) : (
                                    <></>
                                  )}
                                  <Col className="timeline-column" md={6}>
                                    <div className="timeline-box">
                                      <div className="timeline-date bg-primary text-center rounded">
                                        <h3 className="mb-0 text-white-50">
                                          {event.date.getDate()}
                                        </h3>
                                        <p className="text-white mb-0">
                                          {monthNames[event.date.getMonth()]}
                                        </p>
                                        <h3 className="mb-0 text-white-50 year">
                                          {event.date.getFullYear()}
                                        </h3>
                                      </div>
                                      <div className="event-content">
                                        <div className="timeline-text">
                                          <h3 className="font-size-18">
                                            {event.title}
                                          </h3>
                                          <hr />
                                          <div className="mb-0 mt-2 pt-1 text-muted">
                                            <span>{event.description}</span>
                                            {event.videoUrl === null ||
                                            event.videoUrl === "" ? null : (
                                              <>
                                                <div className="embed-responsive embed-responsive-21by9 ratio ratio-21x9 timeline-media">
                                                  <video
                                                    controls
                                                    title={event.videoTitle}
                                                  >
                                                    <source
                                                      src={event.videoUrl}
                                                      type="video/mp4"
                                                    />
                                                  </video>
                                                </div>
                                              </>
                                            )}
                                            <hr />
                                            {event.imageUrl === null ||
                                            event.imageUrl === "" ? null : (
                                              <img
                                                src={event.imageUrl}
                                                alt=""
                                                className="img-fluid rounded timeline-media"
                                              />
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                  {event.direction === "left" ? (
                                    <Col md={6}>
                                      <div className="d-md-block d-none col-md-6">
                                        <div className="timeline-icon">
                                          <i
                                            className={`${event.icon} text-primary h2 mb-0`}
                                          ></i>
                                        </div>
                                      </div>
                                    </Col>
                                  ) : (
                                    <></>
                                  )}
                                </Row>
                              );
                            })}
                          </div>
                          <div className="timeline-start">
                            <p>RIP</p>
                          </div>
                          <p className="user-dod">
                            {timeline.qrData?.userDod.toLocaleDateString()}
                          </p>
                          <div className="timeline-launch">
                            <div className="timeline-box">
                              <div className="timeline-text">
                                <h3 className="font-size-18">
                                  {timeline.qrData?.accolades}
                                </h3>
                                <p className="text-muted mb-0">
                                  {timeline.qrData?.finalWishes}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};