import { assertIsDefined } from "src/common/assert";
import { removeDataSchemaRow } from "src/pages/Utility/DataSchemaHelper";
import { SiemprefyState, SiemprefyTypes } from "./actionTypes";

export const INIT_STATE: SiemprefyState = {
  selectedQR: undefined,
  subscription: {
    isValidSubscription: null,
    email: "",
    subscriptionId: "",
    paymentSource: "",
    deleted: false,
  },
  subscriptionForTimeline: {
    isValidSubscription: null,
    email: "",
    subscriptionId: "",
    paymentSource: "",
    deleted: false,
  },
  errors: [],
  qrs: undefined,
  timelinePreview: undefined,
};

const Siemprefy = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case SiemprefyTypes.LOAD_QRS_SUCCESS:
      return {
        ...state,
        qrs: action.payload,
      };
    case SiemprefyTypes.LOAD_TIMELINE_SUCCESS:
      return {
        ...state,
        selectedQR: action.payload.qr.result[0],
      };
    case SiemprefyTypes.LOAD_QR_ITEM_SUCCESS:
      return {
        ...state,
        selectedQR: action.payload,
      };
    case SiemprefyTypes.CLEAR_TIMELINE:
      return {
        ...state,
        selectedQR: action.payload,
      };
    case SiemprefyTypes.CREATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscription: action.payload,
      };
    case SiemprefyTypes.LOAD_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscription: action.payload.result ? action.payload.result : {},
      };
    case SiemprefyTypes.SUBSCRIPTION_INVALID:
      return {
        ...state,
        subscription: action.payload,
      };
    case SiemprefyTypes.NEW_ERROR:
      return {
        ...state,
        errors: [...state.errors, action.payload],
      };
    case SiemprefyTypes.CLEAR_ERRORS:
      return {
        ...state,
        errors: [],
      };
    case SiemprefyTypes.PREVIEW_TIMELINE_SUCCESS:
      return {
        ...state,
        timelinePreview: action.payload,
      };
    case SiemprefyTypes.LOAD_SUBSCRIPTION_FOR_TIMELINE_COMPLETE:
      return {
        ...state,
        subscriptionForTimeline: action.payload
      };
    case SiemprefyTypes.REMOVE_EVENT_SUCCESS:
      assertIsDefined(state.selectedQR, "A QR must be selected.");

      const newDataRow = removeDataSchemaRow(
        state.selectedQR,
        "qrEvent",
        action.payload
      );

      return {
        ...state,
        selectedQR: {
          ...state.selectedQR,
          data: newDataRow,
        },
      };
    default:
      return state;
  }
};

export default Siemprefy;
