import React, { useRef, useState } from "react";
import { Label, Input, FormFeedback, Button, Spinner } from "reactstrap";
import assert from "assert";
import { FileUploadResponse } from "../Siemprefy/Events/AddEditEvents";
import Axios from "axios";

const uploadFileWithPreSignedUrl = async (presignedUrl: string, file: File) => {
  const result: any = await Axios.put(presignedUrl, file).catch((error) =>
    console.error(error.response.data, { request: error.request })
  );

  console.info(result.data);
  return result.data;
};

export interface SiemprefyFileUploadInputProps {
  accessToken: string;
  label: string;
  name: string;
  validation: any;
}

export const SiemprefyFileUploadInput = (
  props: SiemprefyFileUploadInputProps
) => {
  const hiddenFileInput = React.useRef<any>(null);
  const [uploading, setIsUploading] = useState<boolean>(false);
  const handleClick = () => {
    (hiddenFileInput as any).current.click();
  };
  const handleFileSelected = async (
    e: React.ChangeEvent<HTMLInputElement>,
    field: string
  ): Promise<void> => {
    setIsUploading(true);

    const productId = process.env.REACT_APP_PRODUCTID;
    assert(productId, "ProductId is defined");

    const filesFromInput: any = e.target.files;
    const formData = new FormData();

    formData.append("file", filesFromInput[0], filesFromInput[0].name);

    const presignedUrlResponse: {
      statusCode: number;
      result: string;
    } = await fetch(`${process.env.REACT_APP_OMEGA_SERVER_URI}file/uploadUrl`, {
      method: "POST",
      body: JSON.stringify({
        filename: filesFromInput[0].name,
      }),
      headers: {
        productid: productId,
        Authorization: `Bearer ${props.accessToken}`,
      },
    })
      .then((response) => response.json())
      .catch((e) => {
        console.log(e);
      });

    await uploadFileWithPreSignedUrl(
      presignedUrlResponse.result,
      filesFromInput[0]
    );

    props.validation.setFieldValue(
      field,
      presignedUrlResponse.result.split("?")[0]
    );

    setIsUploading(false);
  };

  return (
    <div className="flex flex-column">
      <Label className="form-label">{props.label}</Label>
      {uploading ? (
        <Spinner className="ms-2 self-align-center" color="primary" />
      ) : (
        <>
          <Button onClick={handleClick}>Upload</Button>
          <input
            ref={hiddenFileInput}
            name={props.name}
            className="form-control form-control-sm"
            id="image-input"
            type="file"
            onChange={(e) => {
              props.validation.setFieldValue("useVideo", false);
              props.validation.setFieldValue("videoUrl", "");
              handleFileSelected(e, props.name);
            }}
            onBlur={props.validation.handleBlur}
            style={{ display: "none" }}
          />
          {props.validation.touched[props.name] &&
          props.validation.errors[props.name] ? (
            <FormFeedback type="invalid">
              {props.validation.errors[props.name]}
            </FormFeedback>
          ) : null}
        </>
      )}
    </div>
  );
};
