import React, { useContext, useState } from "react";
import { Link, Route, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import LoadingPage from "src/pages/Utility/LoadingPage";
import SiemprefyContext from "src/common/Context3nvoy";
import { useSelector } from "react-redux";
import { RootState } from "src/store/state";
import { LoginScreen } from "./LoginScreen";

interface AuthLayoutProps {
  component: any;
  layout: any;
  isAuthProtected: any;
  path?: string;
  exact?: boolean;
  key?: number;
}

const Authmiddleware = ({
  component,
  layout,
  isAuthProtected,
  path,
  exact,
  key,
  ...rest
}: AuthLayoutProps) => {
  const location = useLocation();
  const Layout = layout;
  const Component = component;

  const { isAuthenticated, isLoading, logout } = useAuth0();
  const authError = useSelector((state: RootState) => {
    const e =
      state.Siemprefy.errors.length > 0
        ? state.Siemprefy.errors[state.Siemprefy.errors.length - 1]
        : undefined;

    if (e && e.message === "Unauthorized" && e.statusCode === 401) {
      if (process.env.REACT_APP_LOGOUT?.includes(location.pathname)) {
        return e;
      } else {
        localStorage.removeItem("accessToken");
        logout({
          returnTo: process.env.REACT_APP_LOGOUT,
        });
      }
    } else {
      return undefined;
    }
  });

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthProtected) {
          if (isLoading) {
            return <LoadingPage></LoadingPage>;
          }
        }

        return (
          <Layout>
            {authError === undefined &&
            (isAuthenticated || !isAuthProtected) ? (
              <Component {...props} />
            ) : (
              <LoginScreen></LoginScreen>
            )}
          </Layout>
        );
      }}
    />
  );
};

export default Authmiddleware;
