import React, { useState } from "react";
import {
  Label,
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown,
  Input,
  FormFeedback,
  Col,
  DropdownItem,
  Row,
} from "reactstrap";

export interface DropdownInputParams {
  icon: string;
  label: string;
}

export interface DropdownInputProps {
  label: string;
  name: string;
  options: Array<DropdownInputParams>;
  validation: any;
}

export const SiemprefyDropdownInput = (props: DropdownInputProps) => {
  const [value, setValue] = useState<string>("");

  return (
    <>
      <Label className="form-label">Selected Icon: </Label>
      <span>{value ? <i className={value}></i> : "No selection."}</span>
      {props.validation.touched[props.name] &&
      props.validation.errors[props.name] ? (
        <FormFeedback type="invalid">
          {props.validation.errors[props.name]}
        </FormFeedback>
      ) : null}
      <br />
      <div
        className="btn-group"
        role="group"
        aria-label="Button group with nested dropdown"
      >
        <div className="btn-group" role="group">
          <UncontrolledDropdown>
            <DropdownToggle type="button" color="primary">
              {props.label} <i className="mdi mdi-chevron-down"></i>
            </DropdownToggle>
            <DropdownMenu className="icon-dropdown-container">
              {props.options.map((option, idx) => {
                return (
                  <DropdownItem
                    className="icon-dropdown"
                    type="radio"
                    name={props.name}
                    id={`${option.label}-${idx}`}
                    key={idx}
                    value={option.icon}
                    onClick={(e: any) => {
                      setValue(option.icon);
                      props.validation.handleChange(e);
                    }}
                    onBlur={props.validation.handleBlur}
                    invalid={
                      props.validation.touched[props.name] &&
                      props.validation.errors[props.name]
                        ? 'true'
                        : 'false'
                    }
                    // style={{
                    //   flex: '0 32%',
                    //   height: '100px',
                    //   marginBottom: '2%'
                    // }}
                  >
                    <i className={option.icon}></i>
                    {/* <div className="icon-dropdown-container">
                      <div className="icon-dropdown">
                        <Input
                          type="radio"
                          className="btn-check"
                          name={props.name}
                          id={`${option.label}-${idx}`}
                          onChange={(e) => {
                            setIsOpen(!isOpen);
                            setValue(e.currentTarget.value);
                            props.validation.handleChange(e);
                          }}
                          onBlur={props.validation.handleBlur}
                          value={option.icon}
                          invalid={
                            props.validation.touched[props.name] &&
                            props.validation.errors[props.name]
                              ? true
                              : false
                          }
                        />
                        <label
                          className="btn btn-outline-primary"
                          htmlFor={`${option.label}-${idx}`}
                        >
                          <i className={option.icon}></i>
                        </label>
                      </div>
                    </div> */}
                  </DropdownItem>
                );
              })}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </div>
    </>
  );
};
