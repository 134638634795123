import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";
import { del } from "src/helpers/api_helper";
import * as url from "../../../helpers/url_helper";

//Import Breadcrumb
import QRModal from "../ViewQRModal";
import DatatableTables from "./AdminTables";
import { useDispatch, useSelector } from "react-redux";
import { changePreloader } from "../../../store/layout/actions";
import {
  clearTimeline,
  loadQRItem,
  loadQRs,
} from "src/store/siemprefy/actions";
import SiemprefyContext from "src/common/Context3nvoy";
import { SiemprefyState } from "src/store/siemprefy/actionTypes";
import { RootState } from "src/store/state";
import PreviewTimelineModal from "../PreviewTimelineModal";
import DeleteModal from "src/components/Common/DeleteModal";

export const firePreLoader = (length?: number) => {
  // TODO: Do this through redux!
  const preloader: any = document.getElementById("preloader");

  preloader.style.display = "block";

  setTimeout(
    function () {
      preloader.style.display = "none";
    },
    length ? length : 2000
  );
};

const PageStarter = () => {
  document.title = "Admin | Siemprefy";

  const history = useHistory();
  const dispatch = useDispatch();
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [itemIdToDlete, setItemIdToDlete] = useState<string>("");
  const productId = process.env.REACT_APP_PRODUCTID;
  const context = useContext(SiemprefyContext);
  const deleteQR = async (itemId: string) => {
    await del(`${url.ITEM}/${itemId}`, {
      headers: {
        Authorization: `Bearer ${context.getAccessToken()}`,
        productid: productId,
      },
    });
  };
  const QRs = useSelector((state: RootState) => {
    const siemprefyState = state.Siemprefy as SiemprefyState;

    if (siemprefyState.qrs) {
      const qrs = siemprefyState.qrs;
      qrs.result.forEach((qrData: any) => {
        qrData["actions"] = (
          <div className="flex">
            <Button
              color="primary"
              className="margin"
              onClick={() => {
                history.push(`events?id=${qrData._itemId}`);
              }}
            >
              Manage Events
            </Button>
            <hr />
            <QRModal qr={qrData}></QRModal>
            <hr />
            <Button
              color="primary"
              className="margin"
              onClick={() => {
                history.push(`/qr/?id=${qrData._itemId}`);
                dispatch(loadQRItem(qrData._itemId, context.getAccessToken()));
              }}
            >
              Edit QR
            </Button>
            <hr />
            <PreviewTimelineModal
              username={qrData.userFirstName + " " + qrData.userLastName}
              timelineId={qrData._itemId}
            ></PreviewTimelineModal>
            <Button
              color="primary"
              className="margin"
              onClick={() => {
                history.push(`/?id=${qrData._itemId}`);
              }}
            >
              Go to Timeline
            </Button>
            <hr />
            <Button
              className="margin"
              onClick={async () => {
                setIsDeleting(true);
                setItemIdToDlete(qrData._itemId);
              }}
            >
              Delete
            </Button>
          </div>
        );
      });

      return qrs;
    }
  });

  useEffect(() => {
    dispatch(clearTimeline());
    if (isDeleting) {
      dispatch(changePreloader(true));
    }

    dispatch(loadQRs(context.getAccessToken()));
  }, [isDeleting]);

  return (
    <>
      <DeleteModal
        show={isDeleting}
        onCloseClick={() => setIsDeleting(false)}
        onDeleteClick={async () => {
          firePreLoader(3000);

          // TODO: Move this delete to Redux
          await deleteQR(itemIdToDlete);

          dispatch(loadQRs(context.getAccessToken()));

          setIsDeleting(false);
        }}
      ></DeleteModal>
      <DatatableTables data={QRs}></DatatableTables>
    </>
  );
};

export default PageStarter;
