import React, { SyntheticEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Alert, Col, Container, Row } from "reactstrap";
import { assertIsDefined } from "src/common/assert";
import { SiemprefyState } from "src/store/siemprefy/actionTypes";
import { pluckTimelineDataSchemas } from "../Utility/DataSchemaHelper";

export const LockScreen = () => {
  document.title = `Lock Screen | Siemprefy`;

  const state: SiemprefyState = useSelector((state: any) => state.Siemprefy);
  const [incorrectPwd, setIncorrectPwd] = useState(false);

  const verifyPwd = (e: SyntheticEvent) => {
    e.preventDefault();

    let result = false;
    const element: any = document.getElementById("userpassword");

    //dispatch(unlockTimeline(value));

    // TODO: This is not secure for the POC
    // This needs to get updated to a legit securely password check
    // as soons as I've got at least one paying customer!

    assertIsDefined(state.selectedQR, "Timeline is required.");

    const qr = pluckTimelineDataSchemas(state.selectedQR);
    if (element.value === qr.qrData.password) {
      localStorage.setItem("unlocked", element.value);
      window.location.reload();
    } else {
      setIncorrectPwd(true);
    }

    return result;
  };

  return (
    <>
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col className="col-xxl-12 col-lg-12 col-md-12">
              <div className="auth-full-page-content d-flex p-sm-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="auth-content my-auto">
                      <div className="text-center">
                        <h5 className="mb-0">Lock Screen</h5>
                      </div>
                      <form className="custom-form mt-4">
                        <div className="mb-3">
                          {incorrectPwd && (
                            <Alert color="danger">Password is incorrect.</Alert>
                          )}
                          <label className="form-label" htmlFor="userpassword">
                            Password
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            id="userpassword"
                            placeholder="Enter password"
                          />
                        </div>
                        <div className="mb-3 mt-4">
                          <button
                            className="btn btn-primary w-100 waves-effect waves-light"
                            type="submit"
                            onClick={verifyPwd}
                          >
                            Unlock
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default LockScreen;
function dispatch(arg0: any) {
  throw new Error("Function not implemented.");
}
