import { useAuth0 } from "@auth0/auth0-react";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import SiemprefyContext from "src/common/Context3nvoy";

export const LoginScreen = () => {
  const context = useContext(SiemprefyContext);
  const { getAccessTokenWithPopup } = useAuth0();

  const fetchToken = async () => {
    try {
      const accessToken = await getAccessTokenWithPopup({
        ignoreCache: true,
        useRefreshTokens: true,
      });
      context.setAccessToken(accessToken);
    } catch (e) {
      if ((e as any).error === "login_required") {
        // logout();
      } else {
        // Do something else...
      }

      // What should I return here?
      return context.getAccessToken();
    }
  };

  return (
    <div className="page-content">
      <div className="container-fluid">
        <h2 className="align-text-center">
          <Link
            to="#"
            className="text-primary"
            onClick={() => {
              fetchToken();
            }}
          >
            You need to log-in.
          </Link>
        </h2>
      </div>
    </div>
  );
};
