import React, { useContext, useEffect, useState } from "react";

import { Switch, BrowserRouter as Router } from "react-router-dom";

// Import Routes all
import { userRoutes, authRoutes } from "./routes/allRoutes";

//redux
import { useDispatch, useSelector } from "react-redux";

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware";

// layouts Format
import HorizontalLayout from "./components/HorizontalLayout/index";
import NonAuthLayout from "./components/NonAuth/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";
import "./assets/scss/preloader.scss";
import "./assets/scss/siemprefy.scss";

import SiemprefyContext, {
  SiemprefyContextProvider,
} from "./common/Context3nvoy";
import { loadSubscription } from "./store/siemprefy/actions";
import "url-search-params-polyfill";


const App = () => {
  return (
    <React.Fragment>
      <SiemprefyContextProvider>
        <InnerApp></InnerApp>
      </SiemprefyContextProvider>
    </React.Fragment>
  );
};

const InnerApp = () => {
  const { layoutType } = useSelector((state: any) => ({
    layoutType: state.Layout.layoutType,
  }));

  function getLayout() {
    return HorizontalLayout;
  }
  const Layout = getLayout();

  const { getAccessToken } = useContext(SiemprefyContext);
  const dispatch = useDispatch();

  useEffect(() => {
    if (getAccessToken() !== null) dispatch(loadSubscription(getAccessToken()));
  }, []);

  return (
    <Router>
      <Switch>
        {authRoutes.map((route, idx) => (
          <Authmiddleware
            path={route.path}
            layout={NonAuthLayout}
            component={route.component}
            key={idx}
            isAuthProtected={false}
            exact
          />
        ))}

        {userRoutes.map((route: any, idx: number) => (
          <Authmiddleware
            path={route.path}
            layout={Layout}
            component={route.component}
            key={idx}
            isAuthProtected={true}
            exact
          />
        ))}
      </Switch>
    </Router>
  );
};

export default App;
