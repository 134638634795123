import assert from "assert";
import axios from "axios";

//apply base url for axios
const API_URL = process.env.REACT_APP_OMEGA_SERVER_URI;
const PRODUCT_ID = process.env.REACT_APP_PRODUCTID;

const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => {
    const result = error.response.data.result
      ? {
          created: new Date(),
          statusCode: error.response.data.statusCode,
          message: error.response.data.result.message,
          name: codeToName(error.response.data.statusCode),
        }
      : {
          created: new Date(),
          statusCode: error.response.status,
          message: error.response.data.message,
          name: codeToName(error.response.status),
        };
    return Promise.reject(result);
  }
);

export async function get(url: string, config = {}) {
  return await axiosApi
    .get(url, { ...config })
    .then((response) => response.data);
}

export async function post(url: string, data: any, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function put(
  itemId: string,
  url: string,
  data: any,
  config = {},
  accessToken: string
) {
  assert(accessToken, "Authentication is required!");

  return axiosApi
    .put(`${url}/${itemId}`, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function del(url: string, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then((response) => response.data);
}
function codeToName(statusCode: number): string {
  const names: {
    [code: number]: string;
  } = {
    401: "Unauthorized",
    403: "Forbidden",
  };

  return names[statusCode];
}
