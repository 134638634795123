import { OmegaError } from "src/common/omegaError";
import {
  ItemWithKnownData,
  OmegaResponse,
  QRData,
  QREvent,
  QRSubscriptionView,
} from "src/common/Siemprefy";

/**
 * Interface for Layout type
 */
export enum SiemprefyTypes {
  CLEAR_TIMELINE = "@@siemprefy/CLEAR_TIMELINE",
  LOAD_QRS = "@@siemprefy/LOAD_QRS",
  LOAD_QRS_SUCCESS = "@@siemprefy/LOAD_QRS_SUCCESS",
  LOAD_TIMELINE = "@@siemprefy/LOAD_TIMELINE",
  LOAD_TIMELINE_SUCCESS = "@@siemprefy/LOAD_TIMELINE_SUCCESS",
  LOAD_QR_ITEM = "@@siemprefy/LOAD_QR",
  LOAD_QR_ITEM_SUCCESS = "@@siemprefy/LOAD_QR_SUCCESS",
  UNLOCK_TIMELINE = "@@siemprefy/UNLOCK_TIMELINE",
  CREATE_SUBSCRIPTION = "@@siemprefy/CREATE_SUBSCRIPTION",
  CREATE_SUBSCRIPTION_SUCCESS = "@@siemprefy/CREATE_SUBSCRIPTION_SUCCESS",
  SUBSCRIPTION_INVALID = "@@siemprefy/SUBSCRIPTION_INVALID",
  LOAD_SUBSCRIPTION = "@@siemprefy/LOAD_SUBSCRIPTION",
  LOAD_SUBSCRIPTION_SUCCESS = "@@siemprefy/LOAD_SUBSCRIPTION_SUCCESS",
  NEW_ERROR = "@@siemprefy/NEW_ERROR",
  CLEAR_ERRORS = "@@siemprefy/CLEAR_ERRORS",
  REMOVE_EVENT = "@@siemprefy/REMOVE_EVENT",
  REMOVE_EVENT_SUCCESS = "@@siemprefy/REMOVE_EVENT_SUCCESS",
  PREVIEW_TIMELINE = "@@siemprefy/PREVIEW_TIMELINE",
  PREVIEW_TIMELINE_SUCCESS = "@@siemprefy/PREVIEW_TIMELINE_SUCCESS",
  LOAD_SUBSCRIPTION_FOR_TIMELINE_COMPLETE = "@@siemprefy/LOAD_SUBSCRIPTION_FOR_TIMELINE_COMPLETE",
  PUT_EVENT = "@@siemprefy/PUT_EVENT",
  PUT_EVENT_SUCCESS = "@@siemprefy/PUT_EVENT_SUCCESS",
}

export interface SiemprefyState {
  qrs: OmegaResponse<Array<QRData>> | undefined;
  selectedQR: ItemWithKnownData<QREvent | QRData> | undefined;
  subscription: QRSubscriptionView;
  subscriptionForTimeline: QRSubscriptionView;
  errors: Array<OmegaError>;
  timelinePreview:
    | OmegaResponse<Array<ItemWithKnownData<QREvent | QRData>>>
    | undefined;
}
