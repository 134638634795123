import React, { FC, useContext, useEffect, useState } from "react";
import { Row, Modal } from "reactstrap";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { previewTimeline } from "src/store/siemprefy/actions";
import SiemprefyContext from "src/common/Context3nvoy";
import { SiemprefyState } from "src/store/siemprefy/actionTypes";
import { RootState } from "src/store/state";
import {
  pluckTimelineDataSchemas,
  TimelineDataSchemas,
} from "../Utility/DataSchemaHelper";
import {
  ItemWithKnownData,
  QREvent,
  QRData,
  OmegaResponse,
} from "src/common/Siemprefy";
import { SiemprefyTimeline } from "../Utility/SiemprefyTimeline";

export interface PreviewTimelineModal {
  username: string;
  timelineId: string;
}

const PreviewTimelineModal: FC<PreviewTimelineModal> = (props: any) => {
  const [modal_standard, setmodal_standard] = useState(false);
  const context = useContext(SiemprefyContext);
  const dispatch = useDispatch();
  const state: TimelineDataSchemas | undefined = useSelector(
    (state: RootState) => {
      if (state.Siemprefy.timelinePreview) {
        const timelineData = pluckTimelineDataSchemas(
          state.Siemprefy.timelinePreview.result[0]
        );

        return timelineData;
      } else {
        return undefined;
      }
    }
  );

  function tog_standard() {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  useEffect(() => {
    dispatch(previewTimeline(props.timelineId, context.getAccessToken()));
  }, []);

  return (
    <React.Fragment>
      <Row>
        <div>
          <button
            type="button"
            onClick={() => {
              tog_standard();
            }}
            className="btn btn-primary margin"
            data-toggle="modal"
            data-target="#myModal"
          >
            Preview Timline
          </button>

          <Modal
            size="lg"
            style={{ width: "100%" }}
            isOpen={modal_standard}
            toggle={() => {
              tog_standard();
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                Preview for {props.username}
              </h5>
              <button
                type="button"
                onClick={() => {
                  setmodal_standard(false);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {!state ? (
                "Loading..."
              ) : (
                <SiemprefyTimeline timeline={state}></SiemprefyTimeline>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => {
                  tog_standard();
                }}
                className="btn btn-secondary "
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </Modal>
        </div>
      </Row>
    </React.Fragment>
  );
};

PreviewTimelineModal.propTypes = {
  timelineId: PropTypes.any,
};

export default PreviewTimelineModal;
