import React, { useContext, useEffect, useState } from "react";
import LoadingPage from "./LoadingPage";
import { useHistory } from "react-router-dom";
import { LockScreen } from "../AuthenticationInner/LockScreen";
import { QRData, QREvent, QRSubscriptionView } from "src/common/Siemprefy";
import { useDispatch, useSelector } from "react-redux";
import { loadTimeline } from "src/store/siemprefy/actions";
import { pluckTimelineDataSchemas } from "./DataSchemaHelper";
import ContactOwner from "../AuthenticationInner/ContactOwner";
import { RootState } from "src/store/state";
import SiemprefyContext from "src/common/Context3nvoy";
import { SiemprefyTimeline } from "./SiemprefyTimeline";

export interface QRTimeline {
  qrData: QRData | undefined;
  qrEvents: Array<QREvent> | undefined;
}

const PageTimeline = () => {
  const [smoothLoadComplete, setSmoothLoadComplete] = useState<boolean>(false);
  const [userFound, setUserFound] = useState<QRTimeline>();

  document.title = userFound
    ? `Siemprefy | ${userFound.qrData?.userFirstName} ${userFound.qrData?.userLastName}`
    : "Siemprefy";

  const history = useHistory();
  const subscription: QRSubscriptionView = useSelector((state: RootState) => {
    if (state.Siemprefy.selectedQR && !userFound) {
      const timelineData = pluckTimelineDataSchemas(state.Siemprefy.selectedQR);

      setUserFound(timelineData);
    }

    return state.Siemprefy.subscriptionForTimeline;
  });

  const dispatch = useDispatch();
  const lockStatus = localStorage.getItem("unlocked");
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");
  const { getAccessToken } = useContext(SiemprefyContext);

  useEffect(() => {
    (async () => {
      if (id) {
        dispatch(loadTimeline(id, getAccessToken()));
        setTimeout(() => {
          setSmoothLoadComplete(true);
        }, 3000);
      }
    })();
  }, []);

  if (id === null) {
    history.push("/admin");
  } else if (!smoothLoadComplete) {
    return <LoadingPage></LoadingPage>;
  } else if (subscription.isValidSubscription === null) {
    return <LoadingPage></LoadingPage>;
  } else {
    return !subscription.isValidSubscription ? (
      <ContactOwner></ContactOwner>
    ) : userFound ? (
      <>
        {subscription.isValidSubscription &&
        userFound.qrData &&
        (userFound.qrData.password === null ||
          userFound.qrData.password === lockStatus) ? (
          <SiemprefyTimeline timeline={userFound}></SiemprefyTimeline>
        ) : (
          <LockScreen></LockScreen>
        )}
      </>
    ) : (
      <LoadingPage></LoadingPage>
    );
  }
};

export default PageTimeline;
