// @flow
import { call, takeEvery, put, fork } from "redux-saga/effects";
import { SiemprefyTypes } from "./actionTypes";
import {
  createSubscription,
  loadTimeline,
  getSubscriptionByTimeline,
  getUsersSubscription,
  removeTimelineEvent,
  loadQRs,
} from "../../helpers/eventService";
import {
  OmegaResponse,
  ItemWithKnownData,
  QRData,
  QREvent,
  OmegaSubscription
} from "src/common/Siemprefy";
import { put as putEvent } from "src/helpers/api_helper";
import { PutEventProps } from "./actions";

// TODO: Refactor the subscription to be passed in as a parameter so we request it only once
// TODO: Refactor the error handling into one single method instead of a bunch of try..catches
export interface SagaPayload<T> {
  type: string;
  payload: T;
}

export function* loadQRsGenerator({ payload: { accessToken } }: any) {
  try {
    const qr: Promise<
      OmegaResponse<Array<ItemWithKnownData<QRData | QREvent>>>
    > = yield call(loadQRs, accessToken);

    yield put({
      type: SiemprefyTypes.LOAD_QRS_SUCCESS,
      payload: qr,
    });
  } catch (e: any) {
    yield put({
      type: SiemprefyTypes.NEW_ERROR,
      payload: e,
    });
  }
}

export function* loadTimelineGenerator({
  payload: { qrId, accessToken },
}: any) {
  try {
    const activeSubscription: Promise<boolean> = yield call(
      getSubscriptionByTimeline,
      qrId,
      accessToken
    );
    const isValidSubscription =
      (activeSubscription as any).result &&
      (activeSubscription as any).result.isValidSubscription;

    if (isValidSubscription) {
      yield put({
        type: SiemprefyTypes.LOAD_SUBSCRIPTION_FOR_TIMELINE_COMPLETE,
        payload: (activeSubscription as any).result
      });

      const qr: Promise<
        OmegaResponse<Array<ItemWithKnownData<QRData | QREvent>>>
      > = yield call(loadTimeline, qrId, accessToken);

      yield put({
        type: SiemprefyTypes.LOAD_TIMELINE_SUCCESS,
        payload: {
          qr,
        },
      });
    } else {
      yield put({
        type: SiemprefyTypes.LOAD_SUBSCRIPTION_FOR_TIMELINE_COMPLETE,
        payload: (activeSubscription as any).result
      });
    }
  } catch (e: any) {
    yield put({
      type: SiemprefyTypes.NEW_ERROR,
      payload: e,
    });
  }
}

export function* previewTimelineGenerator({
  payload: { qrId, accessToken },
}: any) {
  try {
    const qr: Promise<
      OmegaResponse<Array<ItemWithKnownData<QRData | QREvent>>>
    > = yield call(loadTimeline, qrId, accessToken);

    yield put({
      type: SiemprefyTypes.PREVIEW_TIMELINE_SUCCESS,
      payload: qr,
    });
  } catch (e: any) {
    yield put({
      type: SiemprefyTypes.NEW_ERROR,
      payload: e,
    });
  }
}

/**
 * Essentially the same as loadTimeline except without the subscription guard
 * @param itemdId and accessToken
 */
export function* loadQRItemGenerator({ payload: { qrId, accessToken } }: any) {
  try {
    const qr: Promise<
      OmegaResponse<Array<ItemWithKnownData<QRData | QREvent>>>
    > = yield call(loadTimeline, qrId, accessToken);

    yield put({
      type: SiemprefyTypes.LOAD_QR_ITEM_SUCCESS,
      payload: (qr as any).result[0],
    });
  } catch (e: any) {
    yield put({
      type: SiemprefyTypes.NEW_ERROR,
      payload: e,
    });
  }
}

export function* createSubscriptionGenerator(
  payload: SagaPayload<{
    subscription: OmegaSubscription;
    accessToken: string;
  }>
) {
  try {
    const subscriptionResult: Promise<
      OmegaResponse<Array<ItemWithKnownData<OmegaSubscription>>>
    > = yield call(
      createSubscription,
      payload.payload.subscription,
      payload.payload.accessToken
    );

    const downstream: SagaPayload<
      Promise<OmegaResponse<Array<ItemWithKnownData<OmegaSubscription>>>>
    > = {
      type: SiemprefyTypes.CREATE_SUBSCRIPTION_SUCCESS,
      payload: subscriptionResult,
    };
    yield put(downstream);
  } catch (e: any) {
    yield put({
      type: SiemprefyTypes.NEW_ERROR,
      payload: e,
    });
  }
}

export function* loadSubscriptionGenerator(saga: SagaPayload<string>) {
  try {
    const activeSubscription: Promise<OmegaSubscription> = yield call(
      getUsersSubscription,
      saga.payload
    );

    yield put({
      type: SiemprefyTypes.LOAD_SUBSCRIPTION_SUCCESS,
      payload: activeSubscription,
    });
  } catch (e: any) {
    yield put({
      type: SiemprefyTypes.NEW_ERROR,
      payload: e,
    });
  }
}

export function* removeEventGenerator(
  saga: SagaPayload<{
    eventId: number;
    accessToken: string;
  }>
) {
  try {
    const response: Promise<OmegaSubscription> = yield call(
      removeTimelineEvent,
      saga.payload.eventId,
      saga.payload.accessToken
    );

    const itemId = (response as any).result[0]._itemId;

    yield put({
      type: SiemprefyTypes.REMOVE_EVENT_SUCCESS,
      payload: saga.payload.eventId,
    });
  } catch (e: any) {
    yield put({
      type: SiemprefyTypes.NEW_ERROR,
      payload: e,
    });
  }
}

export function* putEventGenerator(saga: SagaPayload<PutEventProps>) {
  try {
    const response: Promise<OmegaSubscription> = yield call(
      putEvent,
      saga.payload.itemId,
      saga.payload.url,
      saga.payload.itemToUpdate,
      saga.payload.config,
      saga.payload.accessToken
    );


    yield put({
      type: SiemprefyTypes.PUT_EVENT_SUCCESS,
      payload: response
    });
  } catch (e: any) {
    yield put({
      type: SiemprefyTypes.NEW_ERROR,
      payload: e,
    });
  }
}

function* SiemprefySaga() {
  yield takeEvery(SiemprefyTypes.LOAD_TIMELINE, loadTimelineGenerator);
  yield takeEvery(
    SiemprefyTypes.CREATE_SUBSCRIPTION,
    createSubscriptionGenerator
  );
  yield takeEvery(SiemprefyTypes.LOAD_QRS, loadQRsGenerator);
  yield takeEvery(SiemprefyTypes.LOAD_SUBSCRIPTION, loadSubscriptionGenerator);
  yield takeEvery(SiemprefyTypes.LOAD_QR_ITEM, loadQRItemGenerator);
  yield takeEvery(SiemprefyTypes.REMOVE_EVENT, removeEventGenerator);
  yield takeEvery(SiemprefyTypes.PREVIEW_TIMELINE, previewTimelineGenerator);
  yield takeEvery(SiemprefyTypes.PUT_EVENT, putEventGenerator);
}

export default SiemprefySaga;
