import React, { useState } from "react";

//Import Icons

import { Link } from "react-router-dom";

// Redux Store
import { toggleLeftmenu } from "../../store/actions";
// reactstrap

// Import menuDropdown
import LightDark from "../CommonForBoth/Menus/LightDark";

// import images
import logo from "../../assets/images/logo-sm.svg";

//redux
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "reactstrap";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";

const Header = (props: any) => {
  const dispatch = useDispatch();
  const { leftMenu, layoutMode } = useSelector((state: any) => ({
    leftMenu: state.Layout.leftMenu,
    layoutMode: state.Layout.layoutMode,
    showRightSidebar: state.Layout.showRightSide,
  }));

  const { logout, isAuthenticated } = useAuth0();
  const link = "/Admin";

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex ">
            <Link to={link} className="logo">
              <span className="logo-sm">
                <img src={logo} alt="" height="24" className="svg-color" />
              </span>
              <span className="logo-lg">
                <img
                  src={logo}
                  alt=""
                  height="24"
                  className="svg-color"
                  style={{ color: "white" }}
                />
                <span className="logo-txt">Siemprefy</span>
              </span>
            </Link>
          </div>
          <div className="d-flex">
            {/* light / dark mode */}
            <LightDark
              layoutMode={layoutMode}
              onChangeLayoutMode={props.onChangeLayoutMode}
            />
          </div>
          {isAuthenticated && process.env.NODE_ENV === "development" && (
            <NotificationDropdown />
          )}
          {isAuthenticated && (
            <div className="d-flex">
              <Button
                color="danger"
                onClick={() => {
                  localStorage.removeItem("accessToken");
                  logout({
                    returnTo: process.env.REACT_APP_LOGOUT,
                  });
                }}
              >
                Logout
              </Button>
            </div>
          )}
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;
