import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Button } from "reactstrap";

import { OmegaResponse, QRData } from "src/common/Siemprefy";
import Table from "../QRTable/Table";
import { useDispatch } from "react-redux";
import { clearTimeline } from "src/store/siemprefy/actions";
import { changePreloader } from "src/store/layout/actions";

interface DatabaleColumns {
  Header: string;
  columns: Array<any>;
}

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
function DatatableTables(props: {
  data: OmegaResponse<Array<QRData>> | undefined;
}) {
  document.title = "QRs | Siemprefy";

  const history = useHistory();
  const dispatch = useDispatch();

  if (props.data && props.data.result.length > 0) {
    let columnas: any;
    let datos: Array<any> = [];

    const dataWithTransformedDates = props.data.result.map((qr: QRData) => {
      return {
        ...qr,
        userDob: new Date(qr.userDob).toLocaleDateString(),
        userDod: new Date(qr.userDod).toLocaleDateString(),
        created: new Date(qr.created).toLocaleDateString(),
      };
    });
    dataWithTransformedDates.forEach((qr: any) => {
      const colObject: DatabaleColumns = {
        Header: "QRs",
        columns: [],
      };

      const ignore = [
        "qrDataId",
        "accolades",
        "finalWishes",
        "password",
        "_itemId",
        "itemId",
        "productId",
        "qrCode",
        "ownerId",
        "deleted",
        "name",
        "description",
      ];

      const keys = Object.keys(qr);

      keys.forEach((col: string) => {
        if (!ignore.includes(col)) {
          colObject.columns.push({
            Header: capitalizeFirstLetter(col)
              .replace(/([A-Z])/g, " $1")
              .trim(),
            accessor: col,
            canFilter: false,
          });
        }
      });

      columnas = colObject;
      datos.push(qr);
    });

    const columns = [, columnas];
    const data = datos;

    return (
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Admin" breadcrumbItem="Manage QRs" />
          <Table columns={columns} data={data} />
        </div>
      </div>
    );
  } else {
    return (
      <div className="page-content">
        <div className="container-fluid align-text-center">
          <h3 className="horizontal-align">No data yet.</h3>
          <Button
            type="button"
            color="primary"
            onClick={() => {
              dispatch(clearTimeline());
              history.push("/qr");
            }}
          >
            Create QR
          </Button>
        </div>
      </div>
    );
  }
}

DatatableTables.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
  data: PropTypes.object,
};

export default DatatableTables;
