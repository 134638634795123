import React, { useState } from "react";
import { Row, Modal } from "reactstrap";
import PropTypes from "prop-types";

//Import Breadcrumb

const QRModal = (props: any) => {
  const [modal_standard, setmodal_standard] = useState(false);
  const [modal_large, setmodal_large] = useState(false);
  const [modal_xlarge, setmodal_xlarge] = useState(false);
  const [modal_small, setmodal_small] = useState(false);
  const [modal_center, setmodal_center] = useState(false);
  const [modal_scroll, setmodal_scroll] = useState(false);
  const [modal_longscroll, setmodal_longscroll] = useState(false);

  const [modal_fullscreen, setmodal_fullscreen] = useState(false);
  const [modal_backdrop, setmodal_backdrop] = useState(false);
  const [modal_between_modal, setmodal_between_modal] = useState(false);
  const [modal_between_secondmodal, setmodal_between_secondmodal] = useState(
    false
  );
  const [modal_varyingmodal, setmodal_varyingmodal] = useState(false);
  const [varyingtitle, setvaryingtitle] = useState("@mdo");

  function tog_standard() {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  }

  function tog_fullscreen() {
    setmodal_fullscreen(!modal_fullscreen);
    removeBodyCss();
  }

  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  function tog_large() {
    setmodal_large(!modal_large);
    removeBodyCss();
  }

  function tog_xlarge() {
    setmodal_xlarge(!modal_xlarge);
    removeBodyCss();
  }

  function tog_small() {
    setmodal_small(!modal_small);
    removeBodyCss();
  }

  function tog_center() {
    setmodal_center(!modal_center);
    removeBodyCss();
  }

  function tog_scroll() {
    setmodal_scroll(!modal_scroll);
    removeBodyCss();
  }

  function tog_longscroll() {
    setmodal_longscroll(!modal_longscroll);
    removeBodyCss();
  }

  function tog_between_modal() {
    setmodal_between_modal(!modal_between_modal);
    removeBodyCss();
  }

  function tog_between_secondmodal() {
    setmodal_between_secondmodal(!modal_between_secondmodal);
    removeBodyCss();
  }

  function tog_varyingmodal(title: any) {
    setvaryingtitle(title);
    setmodal_varyingmodal(!modal_varyingmodal);
    removeBodyCss();
  }

  return (
    <React.Fragment>
      <Row>
        <div>
          <button
            type="button"
            onClick={() => {
              tog_standard();
            }}
            className="btn btn-primary margin"
            data-toggle="modal"
            data-target="#myModal"
          >
            View QR
          </button>

          <Modal
            isOpen={modal_standard}
            toggle={() => {
              tog_standard();
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                QR for {props.qr.userFirstName} {props.qr.userLastName}
              </h5>
              <button
                type="button"
                onClick={() => {
                  setmodal_standard(false);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body align-self-center">
              <img src={props.qr.qrCode}></img>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => {
                  tog_standard();
                }}
                className="btn btn-secondary "
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </Modal>
        </div>
      </Row>
    </React.Fragment>
  );
};

QRModal.propTypes = {
  qr: PropTypes.any,
};

export default QRModal;
