import { ItemWithKnownData, OmegaResponse, OmegaSubscription, QRData, QREvent, QRSubscriptionView } from "src/common/Siemprefy";
import { SiemprefyTypes } from "./actionTypes";

export const loadQRItem = (qrId: string, accessToken: string) => ({
  type: SiemprefyTypes.LOAD_QR_ITEM,
  payload: { qrId, accessToken },
});

export const loadQRs = (accessToken: string) => ({
  type: SiemprefyTypes.LOAD_QRS,
  payload: { accessToken },
});

export const loadQRsSuccess = (qrs: OmegaResponse<Array<QRData>>) => ({
  type: SiemprefyTypes.LOAD_QRS_SUCCESS,
  payload: qrs,
});

export const loadTimeline = (
  qrId: string,
  accessToken: string
) => ({
  type: SiemprefyTypes.LOAD_TIMELINE,
  payload: { qrId, accessToken }
});

export const previewTimeline = (
  qrId: string,
  accessToken: string
) => ({
  type: SiemprefyTypes.PREVIEW_TIMELINE,
  payload: { qrId, accessToken }
});

export const clearTimeline = () => ({
  type: SiemprefyTypes.CLEAR_TIMELINE,
  payload: undefined,
});

export const subscriptionInvalid = (payload: OmegaSubscription) => ({
  type: SiemprefyTypes.SUBSCRIPTION_INVALID,
  payload,
});

export const unlockTimeline = (password: string) => ({
  type: SiemprefyTypes.UNLOCK_TIMELINE,
  payload: { password },
});

export const createSubscription = (payload: {
  subscription: OmegaSubscription;
  accessToken: string;
}) => ({
  type: SiemprefyTypes.CREATE_SUBSCRIPTION,
  payload: payload,
});

export const loadSubscription = (accessToken: string) => ({
  type: SiemprefyTypes.LOAD_SUBSCRIPTION,
  payload: accessToken,
});

export const clearErrors = () => ({
  type: SiemprefyTypes.CLEAR_ERRORS,
  payload: [],
});

export const removeEvent = (eventId: number, accessToken: string) => ({
  type: SiemprefyTypes.REMOVE_EVENT,
  payload: {
    eventId: eventId,
    accessToken,
  },
});

export interface PutEventProps {
  itemId: string;
  url: string;
  itemToUpdate: ItemWithKnownData<QRData | QREvent>;
  config: any;
  accessToken: string;
}

export const putEvent = (props: PutEventProps) => ({
  type: SiemprefyTypes.PUT_EVENT,
  payload: props,
});
