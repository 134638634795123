import { useAuth0 } from "@auth0/auth0-react";
import * as url from "../../helpers/url_helper";
import QRCode from "qrcode";
import React, { useContext, useEffect, useState } from "react";
import {
  Alert,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Row,
} from "reactstrap";

import * as Yup from "yup";
import { useFormik } from "formik";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { SiemprefyTextInput } from "../Utility/SiemprefyTextInput";
import { SiemprefyDateInput } from "../Utility/SiemprefyDateInput";
import { SiemprefyBoolInput } from "../Utility/SiemprefyBoolInput";
import { SiemprefyPasswordInput } from "../Utility/SiemprefyPasswordInput";
import { QRViewModel } from "src/common/models/qrViewModel";
import { newQR } from "src/helpers/eventService";
import { get, put } from "src/helpers/api_helper";
import {
  DataSchema,
  ItemWithKnownData,
  OmegaResponse,
  QRData,
  QREvent,
} from "src/common/Siemprefy";
import assert from "assert";
import { useHistory } from "react-router-dom";
import { firePreLoader } from "./Admin/Admin";
import { assertIsDefined } from "src/common/assert";
import { loadTimeline } from "src/store/siemprefy/actions";
import { SiemprefyState } from "src/store/siemprefy/actionTypes";
import { getDataSchema, setDataSchema } from "../Utility/DataSchemaHelper";
import { RootState } from "src/store/state";
import SiemprefyContext from "src/common/Context3nvoy";

const AddEditQR = () => {
  document.title = "Create QR | Siemprefy";

  const { getAccessToken } = useContext(SiemprefyContext);
  const dispatch = useDispatch();
  const [loading, setIsLoading] = useState(false);
  const history = useHistory();
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");
  const state = useSelector((state: RootState) => {
    const siemprefyState = state.Siemprefy as SiemprefyState;

    if (siemprefyState.selectedQR) {
      const result = getDataSchema<QRData>("qrData", siemprefyState.selectedQR);

      assertIsDefined(result, "Result was found.");

      return [result].map((data: QRData) => {
        return {
          ...data,
          password: data.password ? data.password : "",
          userDob: new Date(data.userDob.toString())
            .toISOString()
            .split("T")[0],
          userDod: new Date(data.userDod.toString())
            .toISOString()
            .split("T")[0],
        };
      })[0];
    } else {
      return undefined;
    }
  });

  useEffect(() => {
    const qr = state;
    if (id && !qr && !loading) {
      setIsLoading(true);

      dispatch(loadTimeline(id, getAccessToken()));
    } else if (qr) {
      setIsLoading(false);
    }
  });

  async function generateQR(itemId: string): Promise<string> {
    const res = await QRCode.toDataURL(
      `https://app.siemprefy.com?id=${itemId}`
    );

    return res;
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      firstName: state ? state?.userFirstName : "",
      lastName: state ? state?.userLastName : "",
      accolades: state ? state?.accolades : "",
      finalWishes: state ? state?.finalWishes : "",
      dob: state ? state?.userDob : "",
      dod: state ? state?.userDod : "",
      usePassword: state?.password ? true : false,
      password: state ? state?.password : "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Please Enter First Name"),
      lastName: Yup.string().required("Please Enter Last Name"),
      accolades: Yup.string().required("Please Enter Accolades"),
      finalWishes: Yup.string().required("Please Enter Final Wishes"),
      dob: Yup.date().required("Please Enter Date of Birth"),
      dod: Yup.date().required("Please Enter Date of Death"),
      usePassword: Yup.bool().optional(),
      password: Yup.string().when("usePassword", {
        is: true,
        then: Yup.string().required("Please Enter Password"),
      }),
    }),
    onSubmit: async (values) => {
      const productId = process.env.REACT_APP_PRODUCTID;
      assert(productId, "ProductId is defined");

      firePreLoader(5000);

      if (state) {
        const response: OmegaResponse<
          Array<ItemWithKnownData<QRData | QREvent>>
        > = await get(`${url.ITEM}/${id}`, {
          headers: {
            Authorization: `Bearer ${getAccessToken()}`,
            productid: productId,
          },
        });
        const itemToUpdate = response.result[0];

        assert(itemToUpdate.itemId, "ItemId is missing!");

        setDataSchema("qrData", itemToUpdate, {
          ...state,
          userDob: values.dob,
          userDod: values.dod,
          userFirstName: values.firstName,
          userLastName: values.lastName,
          accolades: values.accolades,
          finalWishes: values.finalWishes,
          password: values.usePassword ? values.password : undefined,
          deleted: false,
        });

        await put(
          itemToUpdate.itemId,
          url.ITEM,
          itemToUpdate,
          {
            headers: {
              Authorization: `Bearer ${getAccessToken()}`,
              productid: productId,
            },
          },
          getAccessToken()
        );

        history.push("/admin");
      } else {
        const newQRItem: DataSchema<QRViewModel> = {
          dataSchemaId: "qrDataDataSchemaId",
          qrData: {
            userDob: values.dob,
            userDod: values.dod,
            userFirstName: values.firstName,
            userLastName: values.lastName,
            accolades: values.accolades,
            finalWishes: values.finalWishes,
            password: values.usePassword ? values.password : undefined,
            deleted: false,
          },
          accessToken: getAccessToken()
        };
        const qrItem = await newQR(newQRItem);
        const response: OmegaResponse<
          Array<ItemWithKnownData<QRData | QREvent>>
        > = await get(`${url.ITEM}/${qrItem.result.itemId}`, {
          headers: {
            Authorization: `Bearer ${getAccessToken()}`,
            productid: process.env.REACT_APP_PRODUCTID,
          },
        });
        const itemToUpdate = response.result[0];

        assert(itemToUpdate.itemId, "ItemId is missing!");

        const qrCode = await generateQR(itemToUpdate.itemId);
        itemToUpdate.data[0].qrData.qrCode = qrCode;

        const productId = process.env.REACT_APP_PRODUCTID;
        assert(productId, "ProductId is defined");

        await put(
          itemToUpdate.itemId,
          url.ITEM,
          itemToUpdate,
          {
            headers: {
              Authorization: `Bearer ${getAccessToken()}`,
              productid: productId,
            },
          },
          getAccessToken()
        );

        history.push("/admin");
      }
    },
  });

  const { error } = useSelector((state: any) => ({
    error: state && state.login && state.login.error,
  }));

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Admin"
            breadcrumbItem={state ? "Edit QR" : "Create QR"}
            link="/Admin"
          />
          <Row>
            <Col xs={12}>
              <Card>
                <CardHeader>
                  <h4 className="card-title">{state ? "Edit" : "Create"} QR</h4>
                  <p className="card-title-desc">
                    {state
                      ? "Update QR data."
                      : "You can add life events to QRs."}
                  </p>
                </CardHeader>
                <CardBody className="p-4">
                  {error ? <Alert color="danger">{error}</Alert> : null}
                  <Form
                    className="custom-form"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <div className="mb-3">
                      <SiemprefyTextInput
                        label="First Name"
                        name="firstName"
                        validation={validation}
                      ></SiemprefyTextInput>
                      <SiemprefyTextInput
                        label="Last Name"
                        name="lastName"
                        validation={validation}
                      ></SiemprefyTextInput>
                      <SiemprefyTextInput
                        label="Accolades"
                        name="accolades"
                        validation={validation}
                      ></SiemprefyTextInput>
                      <SiemprefyTextInput
                        label="Final Wishes"
                        name="finalWishes"
                        validation={validation}
                      ></SiemprefyTextInput>
                      <SiemprefyDateInput
                        label="Date of Birth"
                        name="dob"
                        validation={validation}
                      ></SiemprefyDateInput>
                      <SiemprefyDateInput
                        label="Date of Death"
                        name="dod"
                        validation={validation}
                      ></SiemprefyDateInput>
                      <SiemprefyBoolInput
                        label="Password Protection"
                        name="usePassword"
                        validation={validation}
                      ></SiemprefyBoolInput>
                      <hr />
                      {validation.values.usePassword && (
                        <SiemprefyPasswordInput
                          label="Password"
                          name="password"
                          validation={validation}
                        ></SiemprefyPasswordInput>
                      )}
                      <button
                        className="btn btn-primary w-100 waves-effect waves-light"
                        type="submit"
                      >
                        Create
                      </button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddEditQR;
