import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";

//Import Icons
import Icon from "@ailibs/feather-react-ts";

//Import images
import avatar3 from "../../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../../assets/images/users/avatar-4.jpg";

//i18n
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/store/state";
import { SiemprefyState } from "src/store/siemprefy/actionTypes";
import { OmegaError } from "src/common/omegaError";
import { timeAgo } from "src/common/timeAgo";
import { clearErrors } from "src/store/siemprefy/actions";

const NotificationDropdown = (props: any) => {
  const dispatch = useDispatch();
  const [menu, setMenu] = useState(false);
  const errors: Array<OmegaError> = useSelector((state: RootState) => {
    const siemprefyState = state.Siemprefy as SiemprefyState;

    return siemprefyState.errors;
  });

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => {
          if (menu) {
            dispatch(clearErrors());
          }
          setMenu(!menu);
        }}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon position-relative"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <Icon name="bell" className="icon-lg" />
          {errors.length === 0 ? (
            <span className="badge bg-success rounded-pill">
              {errors.length}
            </span>
          ) : (
            <span className="badge bg-danger rounded-pill">
              {errors.length}
            </span>
          )}
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {props.t("Notifications")} </h6>
              </Col>
            </Row>
          </div>

          <SimpleBar style={{ height: "230px", paddingLeft: "15px" }}>
            {errors.length === 0 ? (
              <span>Nothing to see here</span>
            ) : (
              <>
                {errors.map((err, idx) => {
                  return (
                    <div key={idx} className="d-flex">
                      <div className="avatar-sm me-3">
                        <span className="avatar-title bg-danger rounded-circle font-size-14">
                          <i className="bx bx-error-circle" />
                        </span>
                      </div>
                      <div className="flex-grow-1">
                        <h6 className="mt-0 mb-1">{props.t(err.name)}</h6>
                        <div className="font-size-12 text-muted">
                          <p className="mb-1">{props.t(err.message)}</p>
                          <p className="mb-0">
                            <i className="mdi mdi-clock-outline" />{" "}
                            {props.t(timeAgo(err.created))}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </SimpleBar>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withTranslation()(NotificationDropdown);

NotificationDropdown.propTypes = {
  t: PropTypes.any,
};
