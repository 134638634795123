import React, { useEffect, useRef, useState } from "react";
import { Label, Input, FormFeedback, Button } from "reactstrap";

export interface InputParams {
  label: string;
  value: string;
}

type Selection = InputParams & {
  idx: number;
};

export interface TextInputProps {
  label: string;
  name: string;
  options: Array<InputParams>;
  validation: any;
}

export const SiemprefyToggleInput = (props: TextInputProps) => {
  const [value, setValue] = useState<Selection | undefined>(undefined);

  const updateClass = (selection: Selection) => {
    const el = document.getElementById(selection.idx.toString());
    if (el) {
      el.className = "btn btn-outline-secondary w-sm btn btn-primary";
    }
  };

  const revertClass = (selection: Selection) => {
    const el = document.getElementById(selection.idx.toString());
    if (el) {
      el.className = "btn btn-outline-primary w-sm btn btn-secondary";
    }
  };

  const input = useRef<HTMLDivElement>(null);

  useEffect(() => {}, []);

  return (
    <>
      <Label className="form-label">{props.label}</Label>
      <br />
      <div
        className="btn-group"
        role="group"
        aria-label="Vertical radio toggle button group"
        ref={input}
      >
        {props.options.map((option, idx) => {
          return (
            <div key={idx}>
              <Input
                type="radio"
                className="btn-check"
                name={props.name}
                id={`${option.label}-${idx}`}
                checked={option.value === props.validation.values[props.name]}
                onChange={(e) => {
                  props.validation.handleChange(e);
                }}
                onBlur={props.validation.handleBlur}
                value={option.value}
                invalid={
                  props.validation.touched[props.name] &&
                  props.validation.errors[props.name]
                    ? true
                    : false
                }
              />
              <label
                className="btn btn-outline-primary"
                htmlFor={`${option.label}-${idx}`}
              >
                {option.label}
              </label>
            </div>
          );
        })}
      </div>
      <br />
    </>
  );
};
