import React, { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import SiemprefyContext from "src/common/Context3nvoy";
import { loadSubscription } from "src/store/siemprefy/actions";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { PayPal } from "./PayPal";

export const Payments = () => {
  const { getAccessToken } = useContext(SiemprefyContext);
  const dispatch = useDispatch();
  
  useEffect(() => {
    if (getAccessToken() !== null) dispatch(loadSubscription(getAccessToken()));
  }, []);
  
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Admin"
            breadcrumbItem={"Manage Payments"}
            link="/Admin"
          />
          <Row>
            <Col xs={12}>
              <Card>
                <CardHeader>
                  <h4 className="card-title">Siemprefy Subscription</h4>
                  <p className="card-title-desc">
                    Manage your Siemprefy subscription
                  </p>
                </CardHeader>
                <CardBody className="self-align-center p-4">
                  <PayPal></PayPal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
