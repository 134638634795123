import { useAuth0 } from "@auth0/auth0-react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Label } from "reactstrap";
import { assertIsDefined } from "src/common/assert";
import SiemprefyContext from "src/common/Context3nvoy";
import { OmegaSubscription } from "src/common/Siemprefy";
import {
  createSubscription,
  loadSubscription,
} from "src/store/siemprefy/actions";
import { SiemprefyState } from "src/store/siemprefy/actionTypes";

export const PayPal = () => {
  const ctx = useContext(SiemprefyContext);
  const [error, setError] = useState(undefined);
  const [render, setRender] = useState(false);
  const paypal = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const state: SiemprefyState = useSelector((state: any) => state.Siemprefy);
  const { getAccessToken } = useContext(SiemprefyContext);
  const { user } = useAuth0();

  const createSub = async () => {
    setRender(true);

    assertIsDefined(user, "user is required to create a subscription");

    (window as any).paypal
      .Buttons({
        createSubscription: (data: any, actions: any, err: any) => {
          return actions.subscription.create({
            plan_id: ctx.planId,
          });
        },
        onApprove: async (data: any, actions: any) => {
          setError(undefined);

          assertIsDefined(
            user.sub,
            "user is required to create a subscription"
          );

          const sub: OmegaSubscription = {
            subscriptionId: data.subscriptionID,
            paymentSource: data.paymentSource,
            deleted: false,
          };

          dispatch(
            createSubscription({
              subscription: sub,
              accessToken: getAccessToken(),
            })
          );

          setTimeout(() => {
            window.location.reload();
          }, 2000);
        },
        onError: async (data: any, actions: any) => {
          setError(data);
        },
      })
      .render(paypal.current);
  };

  useEffect(() => {
    if (state.subscription.isValidSubscription === false) {
      createSub();
    }
  }, []);

  return (
    <>
      <div ref={paypal}></div>
      {error && (
        <Alert color="danger">
          <h2 style={{ color: "black" }}>Error Details</h2>
          {JSON.stringify(error)}
        </Alert>
      )}
      {state.subscription && state.subscription.created ? (
        <>
          <h2>Your Subscription</h2>
          <div className="mb-3">
            <Label className="form-label">Started</Label>
            <p>
              {new Date(state.subscription.created).toLocaleDateString("en-US")}
            </p>
            <Label className="form-label">Active</Label>
            <p>{state?.subscription?.isValidSubscription ? "Yes" : "No"}</p>
            <Label className="form-label">Payment Method</Label>
            <p>{state?.subscription?.paymentSource}</p>
          </div>
        </>
      ) : null}
    </>
  );
};
