import React, { useState } from "react";

import { Link } from "react-router-dom";

// import images
import logo from "../../assets/images/logo-sm.svg";

//redux
import { useSelector, useDispatch } from "react-redux";
import LightDark from "../CommonForBoth/Menus/LightDark";

const Header = (props: any) => {
  const { layoutMode } = useSelector((state: any) => ({
    leftMenu: state.Layout.leftMenu,
    layoutMode: state.Layout.layoutMode,
    showRightSidebar: state.Layout.showRightSide,
  }));
  const link = "/Admin";

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to={link} className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logo} alt="" height="24" className="svg-color" />
                </span>
                <span className="logo-lg">
                  <img src={logo} alt="" height="24" className="svg-color" />
                  <span className="logo-txt">Siemprefy</span>
                </span>
              </Link>

              <Link to={link} className="logo logo-light">
                <span className="logo-sm svg-color">
                  <img src={logo} alt="" height="24" />
                </span>
                <span className="logo-lg">
                  <img src={logo} alt="" height="24" className="svg-color" />
                  <span className="logo-txt">Siemprefy</span>
                </span>
              </Link>
            </div>
          </div>
          <div className="d-flex">
            {/* light / dark mode */}
            <LightDark
              layoutMode={layoutMode}
              onChangeLayoutMode={props.onChangeLayoutMode}
            />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;
