import { combineReducers } from "redux";

// Layout
import Layout from "./layout/reducer";

import Siemprefy from "./siemprefy/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Siemprefy,
});

export default rootReducer;
