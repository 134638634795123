import { all, fork } from "redux-saga/effects"

//Layout
import LayoutSaga from "./layout/saga";

import siemprefySaga from "./siemprefy/saga";

export default function* rootSaga() {
  yield all([
    //public
    fork(LayoutSaga),
    fork(siemprefySaga),
  ]);
}
