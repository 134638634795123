import { useAuth0 } from "@auth0/auth0-react";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { QREvent } from "src/common/Siemprefy";
import {
  loadQRItem,
  loadTimeline as loadTimelineAction,
} from "src/store/siemprefy/actions";
import { useHistory } from "react-router-dom";
import AddEditEventForm from "./AddEditEvents";
import { assertIsDefined } from "src/common/assert";
import { pluckTimelineDataSchemas } from "src/pages/Utility/DataSchemaHelper";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/store/state";
import { SiemprefyState } from "src/store/siemprefy/actionTypes";
import SiemprefyContext from "src/common/Context3nvoy";

export enum EventDirections {
  Left = "left",
  Right = "right",
}

const QREvents = () => {
  document.title = "QR Events | Siemprefy";

  const history = useHistory();
  const [editingEvent, setEditingEvent] = useState<QREvent>();
  const bottomOfThePage = useRef<null | HTMLDivElement>(null);
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");
  const dispatch = useDispatch();
  const createEventEl: any = useRef();
  const { getAccessToken } = useContext(SiemprefyContext);
  const data = useSelector((state: RootState) => {
    const siemprefyState = state.Siemprefy as SiemprefyState;

    if (siemprefyState.selectedQR) {
      const timelineData = pluckTimelineDataSchemas(siemprefyState.selectedQR);

      timelineData.qrEvents.sort((a, b) => {
        return a.qrEventId - b.qrEventId;
      });

      return {
        qrData: timelineData.qrData,
        qrEvents: timelineData.qrEvents,
      };
    } else if (siemprefyState.selectedQR === null) {
      history.push("/pages-404");
    }
  });

  assertIsDefined(id, "QR Id required");

  const loadQRWithEvents = async () => {
    dispatch(loadQRItem(id, getAccessToken()));
  };
  useEffect(() => {
    (async () => {
      await loadQRWithEvents();
    })();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Admin" breadcrumbItem="QR Events" link="/Admin" />
          <Row>
            <Col xs={12}>
              <Card>
                <CardHeader>
                  <h4 className="card-title">QR Data</h4>
                </CardHeader>
                <CardBody className="p-4">
                  <div className="mb-3">
                    <Label className="form-label">First Name</Label>
                    <p>{data?.qrData?.userFirstName}</p>
                    <Label className="form-label">Last Name</Label>
                    <p>{data?.qrData?.userLastName}</p>
                    <Label className="form-label">Accolades</Label>
                    <p>{data?.qrData?.accolades}</p>
                    <Label className="form-label">Final Wishes</Label>
                    <p>{data?.qrData?.finalWishes}</p>
                    <Label className="form-label">DOB</Label>
                    <p>
                      {data?.qrData?.userDob &&
                        new Date(data?.qrData?.userDob).toLocaleDateString()}
                    </p>
                    <Label className="form-label">Password Protection</Label>
                    <p>{data?.qrData?.password ? "Yes" : "No"}</p>
                    <Button
                      color="primary"
                      className="margin"
                      onClick={() => {
                        dispatch(loadTimelineAction(id, getAccessToken()));
                        history.push(`/?id=${id}`);
                      }}
                    >
                      View Timeline
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xs={12}>
              <Card>
                <CardHeader>
                  <h4 className="card-title" ref={createEventEl}>
                    New QR Event
                  </h4>
                  <p className="card-title-desc">Add life events here</p>
                </CardHeader>
                <CardBody className="p-4">
                  <AddEditEventForm
                    key={0}
                    itemId={id}
                    event={editingEvent}
                    onSubmit={() => {
                      setEditingEvent(undefined);
                      loadQRWithEvents();
                      setTimeout(() => {
                        bottomOfThePage.current?.scrollIntoView();
                      }, 2000);
                    }}
                  ></AddEditEventForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            {data?.qrEvents.map((event, idx) => {
              return (
                <Col xs={12} key={event.qrEventId}>
                  <Row>
                    <Card>
                      <CardBody className="p-4">
                        <div className="mb-3" key={event.qrEventId}>
                          <Label className="form-label">Event Title</Label>
                          <p>{event.title}</p>
                          <Label className="form-label">
                            Event Description
                          </Label>
                          <p>{event.description}</p>
                          <Label className="form-label">Event Date</Label>
                          <p>{new Date(event.date).toLocaleDateString()}</p>
                          <Label className="form-label">Event Direction</Label>
                          <p>{event.direction}</p>
                          <Label className="form-label">Event Icon</Label>
                          <p>
                            <i className={event.icon}></i>
                          </p>
                          <Label className="form-label">
                            Event Image Preview
                          </Label>
                          {event.imageUrl === null || event.imageUrl === "" ? (
                            <p>No image selected</p>
                          ) : (
                            <>
                              <br></br>
                              <img
                                src={event.imageUrl}
                                alt=""
                                className="img-fluid rounded timeline-media"
                              />
                              <br></br>
                            </>
                          )}
                          <Label className="form-label">
                            Event Video Preview
                          </Label>
                          {event.videoUrl === null || event.videoUrl === "" ? (
                            <p>No video selected</p>
                          ) : (
                            <>
                              <div className="embed-responsive embed-responsive-21by9 ratio ratio-21x9 timeline-media">
                                <video controls title={event.videoTitle}>
                                  <source
                                    src={event.videoUrl}
                                    type="video/mp4"
                                  />
                                </video>
                              </div>
                            </>
                          )}
                          <hr />
                          <Button
                            color="primary"
                            type="submit"
                            onClick={() => {
                              createEventEl.current.scrollIntoView();
                              setEditingEvent(event);
                            }}
                          >
                            Edit Event
                          </Button>
                        </div>
                      </CardBody>
                    </Card>
                  </Row>
                </Col>
              );
            })}
          </Row>
        </Container>
      </div>
      <div ref={bottomOfThePage}></div>
    </React.Fragment>
  );
};

export default QREvents;
