import React, {  } from "react";
import { useHistory } from "react-router-dom";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
} from "react-table";
import { Button, Card, CardBody } from "reactstrap";
import { useDispatch } from "react-redux";
import { clearTimeline } from "src/store/siemprefy/actions";

interface Globalfilter {
  preGlobalFilteredRows: any;
  globalFilter: any;
  setGlobalFilter: any;
}

// Define a default UI for filtering
function GlobalFilter({
  //this line  [eslint] 'preGlobalFilteredRows' is missing in props validation [react/prop-types]
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}: Globalfilter) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value: any) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <span>
      Search:{" "}
      <input
        className="form-control"
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
      />
    </span>
  );
}

interface columnFilter {
  column: any;
}

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}: columnFilter) {
  const count = preFilteredRows.length;

  return count > 9 ? (
    <input
      className="form-control mt-2"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`Filter`}
    />
  ) : null;
}

interface tableinterface {
  columns: any;
  data: any;
}

function SiemprefyTable({ columns, data }: tableinterface) {
  const defaultColumn = React.useMemo(
    () => ({
      // Default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const history = useHistory();
  const dispatch = useDispatch();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
    },
    useFilters,
    useGlobalFilter
  );

  return (
    <React.Fragment>
      <Button
        type="button"
        color="primary"
        onClick={() => {
          dispatch(clearTimeline());
          history.push("/qr");
        }}
      >
        Create QR
      </Button>
      <Button
        type="button"
        color="primary"
        style={{
          marginLeft: 10,
        }}
        onClick={() => {
          history.push("/payment");
        }}
      >
        Payments
      </Button>
      <hr></hr>
      <GlobalFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={state.globalFilter}
        setGlobalFilter={setGlobalFilter}
      />
      <Card>
        <CardBody>
          <div className="table-responsive">
            <table className="table mb-0">
              <thead>
                {headerGroups.map((headerGroup: any) => (
                  <tr
                    key={headerGroup.id}
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map((column: any) => (
                      <th key={column.id}>
                        {column.render("Header")}
                        {/* Render the columns filter UI */}
                        <div>
                          {column.canFilter ? column.render("Filter") : null}
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row: any, i: number) => {
                  prepareRow(row);
                  return (
                    <tr key={row.id} {...row.getRowProps()}>
                      {row.cells.map((cell: any) => {
                        return (
                          <td
                            key={cell.id}
                            {...cell.getCellProps()}
                            className="align-cell"
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
}

export default SiemprefyTable;
